/* eslint-disable no-undef */
import React from 'react';

import PropTypes from 'prop-types';

import { genericHashLink } from 'react-router-hash-link';

import Grid from '@material-ui/core/Grid';
// import Hidden from '@material-ui/core/Hidden';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import i18n from '../../../languages';
import { pricingsType } from '../../../types/pricingType';

import './index.scss';
import { Divider } from '@material-ui/core';

const ActionBar = ({
  pricing,
  selectedTab,
  setSelectedTab,
  isMobile,
  isSticky,
}) => {
  const Link = genericHashLink(Tab);

  /*
  if (pricing.length < 1) {
    return null;
  }
  */

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue === 4 ? newValue + 1 : newValue);
  };

  /*
  const priceAndSubscription = () => (
    <>
      <Grid item xs={12} sm={2} className="price">
        <h2>
          $
          {pricing && pricing[0].price}
        </h2>
      </Grid>
      <Hidden only={['xs', 'sm']}>
        <Grid item sm={4} className="right-subscribe">
          <a
            href={pricing && pricing[0].url}
            className="btn-primary md arrow-right"
          >
            {type === 'course' ? i18n.t('SUBSCRIBE_COURSE') : i18n.t('SUBSCRIBE_CAREER')}
          </a>
        </Grid>
      </Hidden>
    </>
  );
  */
  console.log('LANG,', i18n.culture);

  return (
    <div className="action-bar">
      <Grid
        container
        direction="row"
        alignItems="stretch"
        className="grid-container"
        justifyContent="space-between"
        style={isSticky ? (isMobile ? {} : { border: 'none' }) : {}}
      >
        <Grid item xs={12} sm={12} className="item">
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            className="tabs-container"
          >
            <Link label={i18n.t('ABOUT')} smooth to="#about" />
            <Link label={i18n.t('CONTENT')} smooth to="#program" />
            <Link label={i18n.t('MENTOR')} smooth to="#mentor" />
            <Link label={i18n.t('FAQS')} smooth to="#faqs" />
            {!isMobile && (
              <Tab
                label=""
                icon={
                  <Divider
                    orientation="vertical"
                    style={{
                      alignSelf: 'auto',
                      backgroundColor: '#A5A5A5',
                      height: '12px',
                      margin: '0px 0px',
                      width: '1px',
                    }}
                  />
                }
                disabled
              />
            )}

            <Link
              label={i18n.t('COURSE')}
              smooth
              to="#fullprogram"
              className="black-link"
            />
          </Tabs>
        </Grid>
        {/* priceAndSubscription(); */}
      </Grid>
    </div>
  );
};

ActionBar.propTypes = {
  pricing: pricingsType,
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.number,
};

ActionBar.defaultProps = {
  pricing: [],
  setSelectedTab: () => {},
  selectedTab: 0,
};

export default ActionBar;
