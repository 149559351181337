import {
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_ERROR,
  GET_BANNER_BY_ID_REQUEST,
  GET_BANNER_BY_ID_SUCCESS,
  GET_BANNER_BY_ID_ERROR,
} from '../constants';

const initialState = {
  error: null,
  loading: true,
  banner: {},
  banners: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BANNERS_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        banners: [...data],
        loading: false,
      };
    }
    case GET_BANNERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_BANNER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BANNER_BY_ID_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        banner: data,
        loading: false,
      };
    }
    case GET_BANNER_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
