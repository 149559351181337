import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LanguageAvailable from '../Modals/LanguageAvailable';
import i18n from '../../../languages';
import trackAction from '../../../utils/tracking';
import { getVariablesToSend } from '../../../utils/getUtmParams';
import EnterEmailModal from '../../Common/Modals/EnterEmail';
import { pricingType } from '../../../types/pricingType';
import languageType from '../../../types/languageType';
import { educationalResourceType } from '../../../types/educationalResourceType';
import './index.scss';
import PaymentMethodModal from '../Modals/PaymentMethod';

const BuyButton = ({
  available_language,
  educationalResource,
  language,
  openDescription,
  pricing,
  status,
  id,
  type,
  isSticky,
  handleSelection,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalRemind, setOpenModalRemind] = useState(false);

  const params = getVariablesToSend() && `&${getVariablesToSend()}`;
  const pricingUrl = pricing.length > 0 && `${pricing[0].url}${params}`;

  const handleClickBuyButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    educationalResource && trackAction('add_to_cart', [educationalResource]);

    /*if (language.code === available_language.code) {
      window.location.href = pricingUrl;
      return null;
    }*/

    setOpenModal(true);

    return false;
  };

  if (status !== 'email_capture_no_date' && status !== 'buyable') {
    return null;
  }

  if (status === 'email_capture_no_date') {
    return (
      <div className="buy-button">
        <button
          onClick={() => setOpenModalRemind(true)}
          className="btn-primary md arrow-right long buy-course"
        >
          {i18n.t('REMIND_ME')}
        </button>
        {openDescription && <p>{i18n.t('REMIND_ME_WHEN')}</p>}
        <EnterEmailModal
          open={openModalRemind}
          handleClose={() => setOpenModalRemind(false)}
          idContent={id}
          contentType={type}
        />
      </div>
    );
  }
  return (
    <div className="buy-button">
      <a
        onClick={handleClickBuyButton}
        href={pricingUrl}
        className="btn-primary md arrow-right long buy-course"
      >
        {i18n.t('BUY_INSCRIPTION')}
      </a>

      {openDescription && (
        <p style={isSticky ? { marginTop: 4 } : { marginTop: 24 }}>
          {i18n.t('OPEN_INSCRIPTION')}
        </p>
      )}
      {/*<LanguageAvailable
        open={openModal}
        handleClose={() => setOpenModal(false)}
        pricingUrl={pricingUrl}
      />*/}
      <PaymentMethodModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        pricingUrl={pricingUrl}
        course={educationalResource}
        handleSelection={handleSelection}
      />
    </div>
  );
};

BuyButton.propTypes = {
  available_language: languageType,
  language: languageType,
  openDescription: PropTypes.bool,
  pricing: PropTypes.arrayOf(pricingType),
  educationalResource: educationalResourceType,
  status: PropTypes.string,
  id: PropTypes.string,
};

BuyButton.defaultProps = {
  available_language: {},
  language: {},
  openDescription: false,
  pricing: [],
  educationalResource: [],
  status: '',
  id: '',
};

export default BuyButton;
