import React, { useState } from 'react';
import './index.scss';

const PauseButton = ({ handleClick, isMobile }) => {
  const [hover, setHover] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  };

  return (
    <div className="pause-button">
      <a
        onClick={handleButtonClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {isMobile ? (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 0C4.92339 0 0 4.92339 0 11C0 17.0766 4.92339 22 11 22C17.0766 22 22 17.0766 22 11C22 4.92339 17.0766 0 11 0ZM8.42743 6.74203H9.31453C9.4609 6.74203 9.58066 6.86179 9.58066 7.00816V14.992C9.58066 15.1384 9.4609 15.2582 9.31453 15.2582H8.42743C8.28106 15.2582 8.1613 15.1384 8.1613 14.992V7.00816C8.1613 6.86179 8.28106 6.74203 8.42743 6.74203ZM12.6855 6.74203H13.5726C13.7189 6.74203 13.8387 6.86179 13.8387 7.00816V14.992C13.8387 15.1384 13.7189 15.2582 13.5726 15.2582H12.6855C12.5391 15.2582 12.4193 15.1384 12.4193 14.992V7.00816C12.4193 6.86179 12.5391 6.74203 12.6855 6.74203ZM1.41935 11.0001C1.41935 16.3182 5.73507 20.5807 11 20.5807C16.3181 20.5807 20.5806 16.265 20.5806 11.0001C20.5806 5.68192 16.2649 1.41942 11 1.41942C5.68185 1.41942 1.41935 5.73515 1.41935 11.0001Z"
              fill="#52E0FF"
            />
          </svg>
        ) : hover ? (
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.5 0C8.28024 0 0 8.28024 0 18.5C0 28.7198 8.28024 37 18.5 37C28.7198 37 37 28.7198 37 18.5C37 8.28024 28.7198 0 18.5 0ZM14.1734 11.3387H15.6653C15.9115 11.3387 16.1129 11.5401 16.1129 11.7863V25.2137C16.1129 25.4599 15.9115 25.6613 15.6653 25.6613H14.1734C13.9272 25.6613 13.7258 25.4599 13.7258 25.2137V11.7863C13.7258 11.5401 13.9272 11.3387 14.1734 11.3387ZM21.3347 11.3387H22.8266C23.0728 11.3387 23.2742 11.5401 23.2742 11.7863V25.2137C23.2742 25.4599 23.0728 25.6613 22.8266 25.6613H21.3347C21.0885 25.6613 20.8871 25.4599 20.8871 25.2137V11.7863C20.8871 11.5401 21.0885 11.3387 21.3347 11.3387ZM2.3871 18.5C2.3871 27.4442 9.64536 34.6129 18.5 34.6129C27.4442 34.6129 34.6129 27.3546 34.6129 18.5C34.6129 9.55585 27.3546 2.3871 18.5 2.3871C9.55585 2.3871 2.3871 9.64536 2.3871 18.5Z"
              fill="#52e0ff"
            />
          </svg>
        ) : (
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.5 0C8.28024 0 0 8.28024 0 18.5C0 28.7198 8.28024 37 18.5 37C28.7198 37 37 28.7198 37 18.5C37 8.28024 28.7198 0 18.5 0ZM14.1734 11.3387H15.6653C15.9115 11.3387 16.1129 11.5401 16.1129 11.7863V25.2137C16.1129 25.4599 15.9115 25.6613 15.6653 25.6613H14.1734C13.9272 25.6613 13.7258 25.4599 13.7258 25.2137V11.7863C13.7258 11.5401 13.9272 11.3387 14.1734 11.3387ZM21.3347 11.3387H22.8266C23.0728 11.3387 23.2742 11.5401 23.2742 11.7863V25.2137C23.2742 25.4599 23.0728 25.6613 22.8266 25.6613H21.3347C21.0885 25.6613 20.8871 25.4599 20.8871 25.2137V11.7863C20.8871 11.5401 21.0885 11.3387 21.3347 11.3387ZM2.3871 18.5C2.3871 27.4442 9.64536 34.6129 18.5 34.6129C27.4442 34.6129 34.6129 27.3546 34.6129 18.5C34.6129 9.55585 27.3546 2.3871 18.5 2.3871C9.55585 2.3871 2.3871 9.64536 2.3871 18.5Z"
              fill="white"
              fillOpacity="0.7"
            />
          </svg>
        )}
      </a>
    </div>
  );
};

export default PauseButton;
