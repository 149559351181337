import React from 'react';
import './index.scss';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

const TextSection = (props) => {
  return (
    <>
      <div className="mentor-biography">
        <div
          className={classNames(['text-section', isMobile ? 'mobile' : ''])}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </div>
    </>
  );
};

export default TextSection;
