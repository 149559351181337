/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';

import EnterEmailModal from '../Modals/EnterEmail';

import { courseType } from '../../../types/coursesType';
import i18n from '../../../languages';
import trackAction from '../../../utils/tracking';

import CourseDurationIcon from '../../../assets/images/course-duration.svg';
import CourseLanguageIcon from '../../../assets/images/course-language.svg';
import CourseOffThePichIcon from '../../../assets/images/off-thepitch.svg';
import CourseOnThePichIcon from '../../../assets/images/on-thepitch.svg';

import './index.scss';

const CourseBoxLink = ({ children, course, status, url, setOpenModal }) => {
  let finalComp = '';

  if (status === 'remind_me' || status === 'pending') {
    finalComp = (
      <div
        className="remindme-item"
        onClick={(event) => {
          event.preventDefault();
          trackAction('select_item', [course]);
          setOpenModal(true);
        }}
      >
        {children}
      </div>
    );
  } else {
    finalComp = (
      <a
        href={url}
        onClick={() => {
          trackAction('select_item', [course]);
        }}
      >
        {children}
      </a>
    );
  }

  return finalComp;
};

const ThumbnailCourse = ({
  index,
  course,
  forceOver,
  forceBig,
  forceSmall,
}) => {
  const [openModal, setOpenModal] = useState(false);

  let url = '';

  const hasSponsor = course.sponsors && course.sponsors.length > 0;

  if (
    course.language &&
    course.language.code &&
    course.language.code !== 'en'
  ) {
    url = `/${course.language.code}/course/${course.id}`;
  } else {
    url = `/course/${course.id}`;
  }

  const language =
    (course.available_language &&
      course.available_language.name &&
      course.available_language.name.substring(0, 3)) ||
    '';

  let isFullWidth = forceBig ? 12 : (index / 3) % 1 === 0;

  let title = course.name;
  const titleWordsQty =
    course.name && course.name.trim().replace(/\s+/gi, ' ').split(' ').length;
  if (titleWordsQty <= 3) {
    title = course.name.replace(/\s/g, '<br />');
  }

  let marginSize = 1;
  let boxSize = 5;

  marginSize = (index / 3) % 1 === 0 ? 1 : 2;
  boxSize = (index / 3) % 1 === 0 ? 5 : 7;

  if (forceBig) {
    marginSize = 1;
    boxSize = 7;
    isFullWidth = true;
  }

  if (forceSmall) {
    marginSize = 2;
    boxSize = 5;
    isFullWidth = false;
  }

  const backgroundImageTypeDisplay =
    course &&
    course.media &&
    (isMobile
      ? `url(${course.media.square})`
      : isFullWidth
      ? `url(${course.media.wide})`
      : `url(${course.media.square})`);

  const getMarginBotton = (isFull, title, sponsor) => {
    if (isFull) {
      return { justifyContent: 'center' };
    } else {
      if (title?.length > 30) {
        if (sponsor) {
          return { marginBottom: '60px' };
        } else {
          return { marginBottom: '-10px' };
        }
      } else {
        if (sponsor) {
          return { marginBottom: '60px' };
        } else {
          return { marginBottom: '-10px' };
        }
      }
    }
  };

  const getYearStart = (value) => {
    if (value.indexOf('-') > -1) {
      return value.split('-')[0];
    }
  };

  return (
    course && (
      <div className={classnames('course-box', { 'has-sponsor': hasSponsor })}>
        <Grid container spacing={0}>
          <Grid item xs={1} sm={1} md={marginSize} />
          <Grid item xs={10} sm={10} className="status">
            {course.status === 'buyable' ? (
              <span>{i18n.t('OPEN_INSCRIPTION')}</span>
            ) : (
              <span>{i18n.t('COMING_SOON')}</span>
            )}
          </Grid>
        </Grid>
        <Fade duration={500}>
          <div
            style={{ backgroundImage: backgroundImageTypeDisplay }}
            className={classnames(
              `course-item item-${isFullWidth ? '12' : '6'}`,
              { 'inverse-over': forceOver },
            )}
          >
            <div className="box-content">
              {hasSponsor && (
                <div className="has-sponsor">
                  <Grid container spacing={0}>
                    <Grid item xs={1} sm={1} md={marginSize} />
                    <Grid item xs={10} sm={10} className="sponsors-images">
                      {course.sponsors.map((sponsor) => (
                        <div key={sponsor.id}>
                          <img
                            src={sponsor.home_image}
                            alt={sponsor.fullname}
                            style={{ marginLeft: 4, maxHeight: 30 }}
                          />
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              )}

              {!hasSponsor && isFullWidth && (
                <div className="has-sponsor" style={{ height: 40 }}></div>
              )}

              <Grid container spacing={0} className="box-content-container">
                <Grid item sm={1} md={marginSize} />

                <Grid
                  item
                  className="item-content"
                  sm={7}
                  md={7}
                  style={getMarginBotton(isFullWidth, title, hasSponsor)}
                >
                  <CourseBoxLink
                    status={course.status}
                    url={url}
                    setOpenModal={setOpenModal}
                    course={course}
                  >
                    <h2
                      style={isFullWidth ? { maxWidth: '40%' } : {}}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <h3>
                      {course.partners &&
                        course.partners[0] &&
                        course.partners[0].fullname}
                    </h3>

                    <div className="over">
                      <ul>
                        <li>
                          <img src={CourseDurationIcon} alt="Time" />
                          <span>{course.effort}</span>
                        </li>
                        {language && (
                          <li>
                            <img src={CourseLanguageIcon} alt="Language" />
                            <span>{language || ''}</span>
                          </li>
                        )}
                        {course.oxygen_subtype === 'on_the_pitch' ? (
                          <li>
                            <img src={CourseOnThePichIcon} alt="Pitch" />
                            <span>ON The Pitch</span>
                          </li>
                        ) : (
                          <li>
                            <img src={CourseOffThePichIcon} alt="Pitch" />
                            <span>OFF The Pitch</span>
                          </li>
                        )}
                      </ul>
                      <div className="btn-white arrow-right md see-program">
                        {course.status === 'remind_me' ||
                        course.status === 'pending'
                          ? i18n.t('REMIND_ME')
                          : i18n.t('LEARN_MORE')}
                      </div>

                      <div className="footer-text">
                        {course.thumbnail_date &&
                          course.thumbnail_date.trim() !== '-' &&
                          i18n.t('BUYABLE_COURSE') +
                            ' ' +
                            getYearStart(course.thumbnail_date.trim())}
                      </div>
                    </div>
                  </CourseBoxLink>
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
        <EnterEmailModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          idContent={course.id}
          contentType={course.type}
          course={course}
        />
      </div>
    )
  );
};

ThumbnailCourse.propTypes = {
  course: courseType,
  index: PropTypes.number,
  forceOver: PropTypes.bool,
  forceBig: PropTypes.bool,
  forceSmall: PropTypes.bool,
};

ThumbnailCourse.defaultProps = {
  course: {},
  index: 0,
  forceOver: false,
  forceBig: false,
  forceSmall: false,
};

export default ThumbnailCourse;
