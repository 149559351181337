import { arrayOf, shape, string, number, boolean, oneOfType } from 'prop-types';

import mediaType from './mediaType';
import { partnersType } from './partnersType';
import { structuresType } from './structureType';
import { pricingsType } from './pricingType';
import languageType from './languageType';
import imageType from './imageType';

export const biographyType = shape({
  name: string,
  error: string,
  loading: boolean,
  profession: oneOfType([string, null]),
  resume_banner: oneOfType([imageType, null]),
  seo: oneOfType([string, null]),
  main_image: oneOfType([imageType, null]),
  image_banner: oneOfType([imageType, null]),
  content: oneOfType([string, null]),
});

export const quoteType = shape({
  text: string,
  image: imageType,
  image_to_left: boolean,
});

export const biographyBodyType = shape({
  quotes: arrayOf(quoteType),
  content: arrayOf(string),
});

export const biographyTypes = arrayOf(biographyType);
