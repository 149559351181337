import { shape, string, number } from 'prop-types';

const imageType = {
  data: shape({
    id: number,
    name: shape({
      attributes: shape({
        url: string,
      }),
    }),
  }),
};

export default imageType;
