import React, { useState } from 'react';
import './index.scss';

const CloseButton = ({ handleClick }) => {
  const [hover, setHover] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  };

  return (
    <div className="close-button">
      <a
        onClick={handleButtonClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover ? (
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 18.5C0 8.28024 8.28024 0 18.5 0C28.7198 0 37 8.28024 37 18.5C37 28.7198 28.7198 37 18.5 37C8.28024 37 0 28.7198 0 18.5ZM2.3871 18.5C2.3871 27.4442 9.64536 34.6129 18.5 34.6129C27.4442 34.6129 34.6129 27.3546 34.6129 18.5C34.6129 9.55585 27.3546 2.3871 18.5 2.3871C9.55585 2.3871 2.3871 9.64536 2.3871 18.5Z"
              fill="#52e0ff"
              fillOpacity="0.7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.6569 12.3431C13.0244 11.9756 13.6203 11.9756 13.9879 12.3431L18.313 16.6685L22.6395 12.3431C23.0071 11.9756 23.603 11.9756 23.9706 12.3431C24.3381 12.7107 24.3381 13.3066 23.9706 13.6742L19.6445 18L23.9706 22.3258C24.3381 22.6934 24.3381 23.2893 23.9706 23.6569C23.603 24.0244 23.0071 24.0244 22.6395 23.6569L18.313 19.3315L13.9879 23.6569C13.6203 24.0244 13.0244 24.0244 12.6569 23.6569C12.2893 23.2893 12.2893 22.6934 12.6569 22.3258L16.9815 18L12.6569 13.6742C12.2893 13.3066 12.2893 12.7107 12.6569 12.3431Z"
              fill="#52e0ff"
              fillOpacity="0.7"
            />
          </svg>
        ) : (
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 18.5C0 8.28024 8.28024 0 18.5 0C28.7198 0 37 8.28024 37 18.5C37 28.7198 28.7198 37 18.5 37C8.28024 37 0 28.7198 0 18.5ZM2.3871 18.5C2.3871 27.4442 9.64536 34.6129 18.5 34.6129C27.4442 34.6129 34.6129 27.3546 34.6129 18.5C34.6129 9.55585 27.3546 2.3871 18.5 2.3871C9.55585 2.3871 2.3871 9.64536 2.3871 18.5Z"
              fill="white"
              fillOpacity="0.7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.6569 12.3431C13.0244 11.9756 13.6203 11.9756 13.9879 12.3431L18.313 16.6685L22.6395 12.3431C23.0071 11.9756 23.603 11.9756 23.9706 12.3431C24.3381 12.7107 24.3381 13.3066 23.9706 13.6742L19.6445 18L23.9706 22.3258C24.3381 22.6934 24.3381 23.2893 23.9706 23.6569C23.603 24.0244 23.0071 24.0244 22.6395 23.6569L18.313 19.3315L13.9879 23.6569C13.6203 24.0244 13.0244 24.0244 12.6569 23.6569C12.2893 23.2893 12.2893 22.6934 12.6569 22.3258L16.9815 18L12.6569 13.6742C12.2893 13.3066 12.2893 12.7107 12.6569 12.3431Z"
              fill="white"
              fillOpacity="0.7"
            />
          </svg>
        )}
      </a>
    </div>
  );
};

export default CloseButton;
