import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

// import api from '../../../../api';
import { config } from '../../../../config/config';
import { isLocalhost } from '../../../../serviceWorker';
import i18n from '../../../../languages';

import './index.scss';
import { Checkbox, TextField } from '@material-ui/core';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '80%',
    maxWidth: 380,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const RequestInfo = ({ open, handleClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <h2>
            {i18n.t('MODAL_REQUEST_INFO_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
        </Grid>
      </Grid>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <p>{i18n.t('MODAL_REQUEST_DESCRIPTION')}</p>
          <form>
            <Grid
              container
              justifyContent="center"
              className="form-content get-program-modal"
              spacing="1"
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  label={`${i18n.t('NAME')} *`}
                  color="secondary"
                  type="text"
                  className="form-input"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastName"
                  label={`${i18n.t('LAST_NAME')} *`}
                  color="secondary"
                  type="text"
                  className="form-input"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="marketingEmail"
                  label="Email *"
                  color="secondary"
                  type="email"
                  className="form-input"
                />
              </Grid>

              <Grid item xs={2} sm={2}>
                <Checkbox
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  style={{ marginRight: 10 }}
                />
              </Grid>
              <Grid item xs={10} sm={10}>
                <small>
                  {i18n.t('MODAL_GET_PROGRAM_DISCLAIMER')}
                  <a href="/es/info/privacy">{i18n.t('PRIVACY_POLICY')}.</a>
                </small>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <small style={{ marginTop: 10 }}>* {i18n.t('MANDATORY_FIELD')}</small>
        <Grid item xs={12}>
          <Button
            style={{ width: '100%' }}
            className="btn-primary md"
            onClick={handleClose}
          >
            {i18n.t('MODAL_REQUEST_SEND')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal className="early-bid-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

RequestInfo.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

RequestInfo.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default RequestInfo;
