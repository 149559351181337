import api from '../../api';
import {
  GET_BANNERS_ERROR,
  GET_FAQS_REQUEST,
  GET_FAQS_SUCCESS,
} from '../constants';

export const getFaqs = (query) => (dispatch) => {
  dispatch({
    type: GET_FAQS_REQUEST,
    meta: api.faqs
      .getHomeFaqs(query)
      .then((response) =>
        dispatch({
          type: GET_FAQS_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BANNERS_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default { getFaqs };
