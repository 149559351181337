import api from '../../api';
import {
  GET_PAGE_BY_SLUG_REQUEST,
  GET_PAGE_BY_SLUG_SUCCESS,
  GET_PAGE_BY_SLUG_ERROR,
} from '../constants';

export const getPageBySlug = (query) => (dispatch) => {
  dispatch({
    type: GET_PAGE_BY_SLUG_REQUEST,
    meta: api.pages
      .getPageBySlug(query)
      .then((response) =>
        dispatch({
          type: GET_PAGE_BY_SLUG_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_PAGE_BY_SLUG_ERROR,
          payload: error.response,
        }),
      ),
  });
};
