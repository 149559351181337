/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getBiographyById = (id, lang) =>
  axios.get(`${config.CMS_API}/biographies/${id}?locale=${lang}`);

export const getBiographies = (query) =>
  axios.get(`${config.CMS_API}/biographies${query}`);

export default { getBiographyById, getBiographies };
