import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import i18n from '../../../languages';
import CourseContent from './CourseContent';
import PriceContent from './PriceContent';
import CourseStartCountdown from './CourseStartCountdown';
import ShareModal from '../../Common/Modals/Share';
import ButtonMoreIcon from '../../../assets/images/button-more.svg';
import './index.scss';
import PartnerModal from '../../Common/Modals/Partner';

const CourseAbout = ({ course, user, isMobile, courseData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openPartnerModal, setOpenPartnerModal] = useState(false);

  return (
    <Container maxWidth="lg">
      <Grid container className="course-about-container">
        <Grid item sm={7} md={8} style={{ width: '100%' }}>
          <p style={{ marginTop: 10, marginBottom: 10 }}>PARTNER</p>
          {course.sponsors && course.sponsors.length > 0 && (
            <Grid container spacing={2} direction="row">
              <Grid item xs={11} sm={11}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing="3"
                  onClick={() => setOpenPartnerModal(true)}
                >
                  {course.sponsors.map((sponsor) => (
                    <Grid item key={Math.random()}>
                      <img
                        src={sponsor.detail_image}
                        alt={sponsor.fullname}
                        style={
                          isMobile
                            ? { marginTop: 5, maxWidth: 110 }
                            : { marginTop: 5, maxWidth: 200 }
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                style={{ marginTop: 10, marginLeft: 'auto' }}
              >
                <img
                  src={ButtonMoreIcon}
                  alt="Ver más"
                  className="btn-close-open"
                  style={{ float: 'right' }}
                  onClick={() => setOpenPartnerModal(true)}
                />
              </Grid>
            </Grid>
          )}
          <div style={{ paddingBottom: 10, paddingTop: 10 }}></div>
          <hr />
          <CourseContent {...course} />
        </Grid>
        <Grid item xs={1} />
        <Grid
          item
          sm={4}
          md={3}
          style={{ width: '100%' }}
          className="price-content"
        >
          {user.logged &&
            course.bought &&
            course.runs_dates &&
            course.runs_dates[0] && (
              <CourseStartCountdown start={course.runs_dates[0].start} />
            )}
          <PriceContent {...course} />

          <div
            className="btn-black share-button"
            onClick={(event) => {
              event.preventDefault();
              setOpenModal(true);
            }}
          >
            {i18n.t('SHARE_COURSE_PROGRAM')}
          </div>
          <ShareModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            idContent={course.id}
            name={course.name}
            description={course.short_description}
          />
        </Grid>
      </Grid>

      <PartnerModal
        isMobile={isMobile}
        sponsors={course.sponsors}
        activeSponsor={1}
        open={openPartnerModal}
        handleClose={(e) => {
          e.preventDefault();
          setOpenPartnerModal(false);
        }}
      />
    </Container>
  );
};

export default CourseAbout;
