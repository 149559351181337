import api from '../../api';
import {
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_SUCCESS,
  GET_PARTNERS_ERROR,
} from '../constants';

export const getPartners = (query) => (dispatch) => {
  dispatch({
    type: GET_PARTNERS_REQUEST,
    meta: api.partners
      .getPartners(query)
      .then((response) =>
        dispatch({
          type: GET_PARTNERS_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_PARTNERS_ERROR,
          payload: error.response,
        }),
      ),
  });
};
