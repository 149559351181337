import {
  GET_BIOGRAPHIES_REQUEST,
  GET_BIOGRAPHIES_SUCCESS,
  GET_BIOGRAPHIES_ERROR,
  GET_BIOGRAPHY_BY_ID_REQUEST,
  GET_BIOGRAPHY_BY_ID_SUCCESS,
  GET_BIOGRAPHY_BY_ID_ERROR,
} from '../constants';

const initialState = {
  error: null,
  loading: true,
  biography: {},
  biographies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BIOGRAPHIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BIOGRAPHIES_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        biographies: [...data],
        loading: false,
      };
    }
    case GET_BIOGRAPHIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_BIOGRAPHY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BIOGRAPHY_BY_ID_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        biography: data,
        loading: false,
      };
    }
    case GET_BIOGRAPHY_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
