/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getCourse = (id) => axios.get(`${config.CMS_API}/courses/${id}`);

export const getCourses = () => axios.get(`${config.CMS_API}/courses`);

export const getCoursesByMentor = (slug) =>
  axios.get(`${config.CMS_API}/biographies/${slug}/courses`);

export default { getCourse, getCourses, getCoursesByMentor };
