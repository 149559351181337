import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import i18n from '../../../languages';
import './index.scss';

const Chronology = (props) => {
  console.log('props', props);
  return (
    <div className={classnames('chronology')}>
      <Grid container direction="row">
        <Grid item xs={12} className="content">
          <div dangerouslySetInnerHTML={{ __html: props.text }} />
        </Grid>
      </Grid>
    </div>
  );
};

Chronology.propTypes = {
  id: PropTypes.number,
  text: PropTypes.string,
  image_to_left: PropTypes.bool,
  image: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      attributes: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

Chronology.defaultProps = {
  id: 1,
  text: '',
  image_to_left: false,
  image: null,
};

export default Chronology;
