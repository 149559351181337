import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Loader from '../Loader';

import './index.scss';

const VideoPlayer = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [muteVideo, setMuteVideo] = useState(props.muted);
  const videoClassName = isMobile ? 'video-mobile' : 'video-web';

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div className={'video-player'}>
      {isLoading && <Loader />}
      <video autoPlay muted loop id="vid" width={'100%'} height={'98%'}>
        <source src={props.url} />
      </video>
    </div>
  );
});

VideoPlayer.displayName = 'VideoPlayer';

VideoPlayer.propTypes = {
  muted: PropTypes.bool,
  url: PropTypes.string,
  style: PropTypes.object,
};

VideoPlayer.defaultProps = {
  muted: false,
  url: '',
  style: null,
};

export default VideoPlayer;
