/* eslint-disable react/no-danger */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import i18n from '../../../languages';

import './index.scss';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ButtonMoreIcon from '../../../assets/images/button-more.svg';
import ButtonMoreWhiteIcon from '../../../assets/images/button-more-white.svg';
import ButtonMoreClosedIcon from '../../../assets/images/button-more-closed.svg';
import ButtonMoreWhiteClosedIcon from '../../../assets/images/button-more-closed-white.svg';

const Faqs = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  console.log('FAQS');
  console.log(props.faqs);
  console.log(props.course);

  let faqList = [];

  if (props.isHome) {
    faqList = props.faqs.filter((el) => el.attributes.course.data === null);
  } else {
    faqList = props.course.faqs;
  }

  if (props.faqs.length > 0) {
    return (
      <div className="course-faqs">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={12} className="content">
              <Grid container className="title">
                <Grid item xs={7} sm={10} className="content">
                  <h2
                    style={
                      props.isHome
                        ? {
                            color: '#52e0ff',
                            fontFamily: 'mono45-headline',
                            fontSize: 52,
                            marginLeft: '3.4rem',
                            marginBottom: '5rem',
                          }
                        : {}
                    }
                    dangerouslySetInnerHTML={{ __html: i18n.t('COURSE_FAQS') }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <div>
          <Container maxWidth="lg">
            <Grid container justifyContent="center">
              <Grid item sm={7} xs={12} className="content-faqs">
                {faqList.map((faq, index) => {
                  return (
                    <ExpansionPanel
                      key={`panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      expanded={expanded === `panel${index}`}
                    >
                      <ExpansionPanelSummary
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                        className="structure"
                      >
                        <Grid
                          container
                          justifyContent="flex-end"
                          className="structure-container"
                        >
                          <Grid item xs={11} sm={11} className="content-main">
                            <h4
                              style={
                                props.isHome
                                  ? {
                                      paddingRight: 5,
                                      color: '#fff',
                                      fontSize: 22,
                                    }
                                  : { paddingRight: 5 }
                              }
                            >
                              {props.isHome
                                ? faq.attributes.question
                                : faq.question}
                            </h4>
                          </Grid>
                          <Grid item xs={1} sm={1} className="more text-right">
                            <>
                              {expanded === `panel${index}` ? (
                                <img
                                  src={
                                    props.isHome
                                      ? ButtonMoreWhiteClosedIcon
                                      : ButtonMoreClosedIcon
                                  }
                                  alt="Ver menos"
                                  className="btn-close-open"
                                />
                              ) : (
                                <img
                                  src={
                                    props.isHome
                                      ? ButtonMoreWhiteIcon
                                      : ButtonMoreIcon
                                  }
                                  alt="Ver más"
                                  className="btn-close-open"
                                />
                              )}
                            </>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>

                      <AccordionDetails
                        className={classnames(
                          expanded === `panel${index}` && 'opened',
                        )}
                      >
                        <Grid item xs={12} sm={12} className="content-faqs">
                          <Grid container className="unit-container">
                            <Grid item xs={12} sm={10}>
                              <div
                                style={
                                  props.isHome
                                    ? {
                                        color: '#fff',
                                        fontSize: 18,
                                        fontFamily: 'D-DIN',
                                      }
                                    : {}
                                }
                                dangerouslySetInnerHTML={{
                                  __html: props.isHome
                                    ? faq.attributes.answer
                                    : faq.answer,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </ExpansionPanel>
                  );
                })}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
  return null;
};

export default Faqs;
