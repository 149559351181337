/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
// import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './index.scss';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { isMobile } from 'react-device-detect';

const SponsorBar = ({ sponsors }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <Box
          p={3}
          className="sponsor-desc"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      className="grid-container"
      justifyContent="space-between"
    >
      <Grid item xs={12} sm={12} className="item">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          className="tabs-container"
        >
          {sponsors.map((sponsor, idx) => (
            <Tab
              key={Math.random()}
              icon={
                <img
                  src={sponsor.detail_image}
                  alt={sponsor.fullname}
                  style={
                    isMobile
                      ? { marginTop: 5, maxWidth: 110 }
                      : { marginTop: 5, maxWidth: 180 }
                  }
                />
              }
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>

        {sponsors.map((sponsor, idx) => (
          <TabPanel key={Math.random()} value={selectedTab} index={idx}>
            {sponsor.description}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

SponsorBar.propTypes = {
  sponsors: PropTypes.array,
};

export default SponsorBar;
