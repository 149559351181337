/* eslint-disable global-require */
/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import classnames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../languages';

import './index.scss';

const ContentSlider = ({ content_slider }) => {
  const componentRef = useRef(null);

  const newContent = content_slider?.content
    ? content_slider.content.split('\n\n')
    : [];

  const title = content_slider?.title ? content_slider?.title : '';

  return (
    <>
      <div
        className="content-slider"
        style={{
          backgroundImage: `url(${content_slider.image?.data?.attributes?.url}`,
        }}
        ref={componentRef}
      >
        <a
          className="anchor"
          name="methodology"
          id="methodology"
          href="#methodology"
        >
          methodology
        </a>
        <div className="content-slider-shadow">
          <Grid container justifyContent="center">
            <Grid item xs={11} sm={10}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: title.replace(/(.-)/g, '$1<br />'),
                }}
              />
            </Grid>
          </Grid>
          <div className="content-slider-container">
            <Grid container className="content" spacing={0}>
              <Grid item sm={1} md={1} xs={12} />
              <Grid item sm={10} md={7} xs={12}>
                {newContent.map((item, index) => (
                  <Grid
                    container
                    key={lodash.uniqueId('slider_')}
                    className={classnames('item')}
                    spacing={0}
                  >
                    <Grid item xs={12} sm={1} md={1}>
                      <h2>0{index + 1}</h2>
                    </Grid>
                    <Grid item xs={12} sm={10} md={9}>
                      <p>{item ? item.split('\n')[1] : ''}</p>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            className="content"
            spacing={0}
            style={{ marginTop: 50 }}
          >
            <Grid item md={1} />
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              style={isMobileOnly ? { textAlign: 'center' } : {}}
            >
              <button
                type="button"
                style={isMobileOnly ? { width: '90%' } : { minWidth: 285 }}
                className="btn-primary content-button arrow-right md"
              >
                {i18n.t('EXPLORE_COURSES')}
              </button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

ContentSlider.propTypes = {
  content_slider: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
};

ContentSlider.defaultProps = {
  content_slider: [],
};

export default ContentSlider;
