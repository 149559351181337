import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import './index.scss';
import SponsorBar from '../../../EducationalResource/SponsorBar';
import Grid from '@material-ui/core/Grid';

const getModalStyle = (isMobile) => {
  const top = 55;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-50%, -${left}%)`,
    maxHeight: isMobile ? '80vh' : 676,
    overflowY: 'auto',
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '80%',
    maxWidth: 670,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

const PartnerModal = ({ open, handleClose, sponsors, isMobile }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle(isMobile));

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <h2>
            <IconButton
              onClick={handleClose}
              className="close"
              style={{ zIndex: 100 }}
            >
              <CloseIcon />
            </IconButton>
          </h2>
        </Grid>
      </Grid>

      <SponsorBar sponsors={sponsors} />
    </div>
  );

  return (
    <Modal className="partner-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

PartnerModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

PartnerModal.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default PartnerModal;
