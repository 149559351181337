import classnames from 'classnames';
import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import i18n from '../../../languages';
import { config } from '../../../config/config';
import { getVariablesToSend } from '../../../utils/getUtmParams';

import VideoMuteIcon from '../../../assets/images/icon-video-muted.svg';
import VideoUnmuteIcon from '../../../assets/images/icon-video-unmuted.svg';
import VideoReplayIcon from '../../../assets/images/icon-video-replay.svg';

import './index.scss';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const Jumbotron = ({
  logged,
  stop,
  setMuteCallback,
  setReplayCallback,
  banners: bannersStore,
  handlePlayVideo,
  isScrolling,
}) => {
  const [mute, setMute] = useState(true);
  const [banner, setBanner] = useState({});
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });

  const [textRotation, setTextRotation] = useState(null);
  const [circleVisible, setCircleVisible] = useState('');

  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [xMain, setXmain] = useState({ x: 0, y: 0 });
  const [xTrailing, setXtraigin] = useState({ x: 0, y: 0 });
  const [cursorClicked, setCursorClicked] = useState(false);
  const [lastPos, setLastPost] = useState({ x: 0, y: 0 });

  const divRef = createRef();

  useEffect(() => {
    const { banners } = bannersStore;
    if (banners.length && banners[0].attributes) {
      const auxBanner = banners[0].attributes;
      let auxTitle = auxBanner.title;
      let auxContent = auxBanner.content;
      const titleWordsQty = auxTitle.trim().replace(/\s+/gi, ' ').split(' ')
        .length;
      if (titleWordsQty <= 3) {
        auxTitle = auxTitle.replace(/\s/g, '<br />');
      }
      setBanner(auxBanner);
      setContent(auxContent);
      setTitle(auxTitle);
    }
  }, [bannersStore]);

  const params = getVariablesToSend() && `&${getVariablesToSend()}`;

  const setMuteClick = (event) => {
    event.preventDefault();
    setMute(!mute);
    setMuteCallback(!mute);
  };

  const setReplayClick = (event) => {
    event.preventDefault();
    setReplayCallback(true);
  };

  const onMouseMove = (e) => {
    const { clientX, clientY } = e;
    const screenW = window.innerWidth / 2;
    const screenH = window.innerHeight / 2;

    if (clientX < screenW - 200 && clientY > screenH) {
      setCircleVisible('circle-invisible');
    } else {
      setCircleVisible('');
    }
    setXmain({ x: clientX, y: clientY });
    setMousePos({ x: clientX, y: clientY });
    setLastPost({ x: clientX, y: clientY });
  };

  const onMouseLeave = (e) => {
    setCircleVisible('circle-invisible');
  };

  useEffect(() => {
    setCursorPosition({
      left: xTrailing.x,
      top: xTrailing.y,
    });
  }, [xTrailing]);

  useEffect(() => {
    setTimeout(() => {
      setXtraigin({
        x: xMain.x,
        y: xMain.y,
      });
    }, 100);
  }, [xMain]);

  useEffect(() => {
    const screenWidth = window.innerWidth / 2;
    if (mousePos.x < screenWidth - 100) {
      setTextRotation('rotateLeft');
    } else if (mousePos.x > screenWidth + 100) {
      setTextRotation('rotateRight');
    } else {
      setTextRotation('rotateCenter');
    }
  }, [mousePos]);

  return (
    <div
      className={classnames('jumbotron')}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        handlePlayVideo();
      }}
    >
      <div
        id="circle"
        style={{ ...cursorPosition }}
        className={classnames(`circle ${circleVisible}`, {
          'circle-invisible': isScrolling,
        })}
        onMouseDown={() => setCursorClicked(true)}
        onMouseUp={() => setCursorClicked(false)}
      >
        {cursorClicked ? (
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34 67C52.2254 67 67 52.2254 67 34C67 15.7746 52.2254 1 34 1C15.7746 1 1 15.7746 1 34C1 52.2254 15.7746 67 34 67Z"
              stroke="#52E0FF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M27.3984 20.8008L47.1984 34.0008L27.3984 47.2008V20.8008Z"
              stroke="#52E0FF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34 67C52.2254 67 67 52.2254 67 34C67 15.7746 52.2254 1 34 1C15.7746 1 1 15.7746 1 34C1 52.2254 15.7746 67 34 67Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M27.3984 20.8008L47.1984 34.0008L27.3984 47.2008V20.8008Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <div id="info" ref={divRef}>
        <Grid container>
          <Grid item sm={5} xs={12} className="content">
            <Grid container>
              <Grid item>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <h3>{content}</h3>

                {!logged && (
                  <a
                    className="create-acount-button"
                    href={`${banner.link}${params}`}
                  >
                    <button
                      type="button"
                      style={isMobile ? {} : { minWidth: 185 }}
                      className="btn-primary md arrow-right create-account"
                    >
                      <span>{banner.label_link?.toUpperCase()}</span>
                    </button>
                  </a>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={7} className="content-right"></Grid>
        </Grid>
      </div>
    </div>
  );
};

Jumbotron.propTypes = {
  logged: PropTypes.bool,
};

Jumbotron.defaultProps = {
  logged: false,
};

export default Jumbotron;
