import { combineReducers } from 'redux';

import course from './course';
import banners from './banners';
import biographies from './biographies';
import degree from './degree';
import educationalResources from './educationalResources';
import enrollments from './enrollments';
import menu from './menu';
import marketingEmail from './marketingEmail';
import partners from './partners';
import user from './user';
import search from './search';
import pages from './pages';
import faqs from './faqs';

export default combineReducers({
  course,
  banners,
  biographies,
  degree,
  educationalResources,
  enrollments,
  menu,
  marketingEmail,
  partners,
  search,
  user,
  pages,
  faqs,
});
