import React from 'react';
import './index.scss';
import laLiga from '../../../assets/images/laliga.svg';
import Marca from '../../../assets/images/marca.svg';
import laLigaBussiness from '../../../assets/images/laligabs.svg';
import radioMarca from '../../../assets/images/radio_logo.svg';
import sue from '../../../assets/images/esue.svg';
import coEducation from '../../../assets/images/coeducation.svg';
import afe from '../../../assets/images/afe.svg';
import austral from '../../../assets/images/austral.svg';

const PartnerBar = ({ partners }) => {
  console.log('Parters', partners);
  return (
    <div className="partner-bar">
      <span className="partner-text">Partners</span>
      <div>
        <img src={laLiga} className="la-liga-logo" />
      </div>
      <div>
        <img src={laLigaBussiness} className="la-liga-bs-logo" />
      </div>
      <div>
        <img src={Marca} className="marca-logo" />
      </div>
      <div>
        <img src={radioMarca} className="radio-logo" />
      </div>
      <div>
        <img src={sue} className="esue-logo" />
      </div>
      <div>
        <img src={coEducation} className="coeducation-logo" />
      </div>
      <div>
        <img src={afe} className="afe-logo" />
      </div>
      <div>
        <img src={austral} className="austral-logo" />
      </div>
    </div>
  );
};

export default PartnerBar;
