import React from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import './index.scss';

const QuoteSection = (props) => {
  return (
    <Grid container className="mentor-biography">
      <Grid item xs={12}>
        <div className="quote-container">
          {isMobile ? (
            <Grid container className="quote-grid-container">
              <Grid item xs={12} md={6} className="quote-grid-item">
                <div
                  style={{
                    backgroundImage: `url(${props.quote?.image?.data?.attributes?.url})`,
                  }}
                  className="quote-image"
                ></div>
              </Grid>
              <Grid item xs={12} md={6} className="quote-grid-item">
                <div
                  className="quote-text"
                  dangerouslySetInnerHTML={{ __html: props.quote?.text }}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container className="quote-grid-container">
              <Grid item xs={12} md={6} className="quote-grid-item">
                {props.quote?.image_to_left ? (
                  <div
                    style={{
                      backgroundImage: `url(${props.quote?.image?.data?.attributes?.url})`,
                    }}
                    className="quote-image"
                  ></div>
                ) : (
                  <div
                    className="quote-text"
                    dangerouslySetInnerHTML={{ __html: props.quote?.text }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} className="quote-grid-item">
                {props.quote?.image_to_left ? (
                  <div
                    className="quote-text"
                    dangerouslySetInnerHTML={{ __html: props.quote?.text }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${props.quote?.image?.data?.attributes?.url})`,
                    }}
                    className="quote-image"
                  ></div>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default QuoteSection;
