/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactPlayer from 'react-player';
import PlayButton from '../../../components/Common/PlayButton';
import PauseButton from '../../../components/Common/PauseButton';
import UnmuteButton from '../../../components/Common/UnmuteButton';
import MuteButton from '../../../components/Common/MuteButton';
import CloseButton from '../../../components/Common/CloseButton';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import './index.scss';
import i18n from '../../../languages';
import FullScreenButton from '../FullScreenButton';
import getOtherSettings from '../../../utils/getOtherSettings';

const FullScreenPlayer = ({
  isMobile,
  course,
  showVideoPlayer,
  setSelectedTab,
  isHome,
  videoToPlay,
}) => {
  const playerRef = useRef();
  const [played, setPlayed] = useState(0);
  const [overPlayer, setOverPlayer] = useState(false);
  const [stopVideo, setStopVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [seeking, setSeeking] = useState(false);

  const format = (seconds) => {
    if (isNaN(seconds)) {
      return '00:00';
    }
    const date = new Date();
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }

    return `${mm}:${ss}`;
  };

  const PrettoSlider = withStyles({
    root: {
      color: '#52e0ff',
      height: 8,
    },
    thumb: {
      height: 10,
      width: 10,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -1,
      marginLeft: -4,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      /*borderRadius: 4,*/
    },
    rail: {
      height: 8,
      /*borderRadius: 4,*/
    },
  })(Slider);

  const playButtonClick = () => {
    setStopVideo(!stopVideo);
  };

  const muteButtonClick = () => {
    setMuteVideo(!muteVideo);
  };

  const handleProgress = (changeState) => {
    if (!seeking) {
      setPlayed(changeState.played);
    }
  };

  const handleSeekChange = (e, newValue) => {
    setPlayed(parseFloat(newValue / 100));
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e, newValue) => {
    setSeeking(false);
    playerRef.current.seekTo(newValue / 100);
  };

  /*const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
  const duration = playerRef.current ? playerRef.current.getDuration() : '00:00';

  const ellapseTime = format(currentTime)
  const totalDuration = format(duration)
  */

  const setTab = (tab, div) => {
    setSelectedTab(tab, div);
  };

  const otherSettings = isHome
    ? null
    : !course.loading && getOtherSettings(course.other_settings);

  const escFunction = useCallback((event) => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      showVideoPlayer();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', escFunction, false);
    document.addEventListener('webkitfullscreenchange', escFunction, false);
    document.addEventListener('mozfullscreenchange', escFunction, false);
    document.addEventListener('MSFullscreenChange', escFunction, false);
    document.addEventListener('mousemove', mouseStopped);

    return () => {
      document.removeEventListener('fullscreenchange', escFunction, false);
      document.removeEventListener(
        'webkitfullscreenchange',
        escFunction,
        false,
      );
      document.removeEventListener('mozfullscreenchange', escFunction, false);
      document.removeEventListener('MSFullscreenChange', escFunction, false);
      document.removeEventListener('mousemove', mouseStopped);
    };
  }, []);

  const mouseStopped = () => {
    setOverPlayer(true);
    if (document.getElementsByClassName('player-container')[0]) {
      document.getElementsByClassName('player-container')[0].style.cursor =
        'initial';
    }
    setTimeout(() => {
      setOverPlayer(false);
      if (document.getElementsByClassName('player-container')[0]) {
        document.getElementsByClassName('player-container')[0].style.cursor =
          'none';
      }
    }, 4000);
  };

  const videoUrl = isHome
    ? videoToPlay
    : isMobile
    ? otherSettings.media_mobile_url
    : otherSettings.promo_url;

  return (
    <Container
      maxWidth={false}
      style={{
        padding: 0,
      }}
      className="player-container"
    >
      {videoUrl && (
        <div
          style={{
            height: '100vh',
            width: '100%',
            backgroundColor: '#000',
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >
            <div
              style={{ position: 'relative' }}
              onMouseEnter={() => setOverPlayer(true)}
              onMouseLeave={() => setOverPlayer(false)}
            >
              <ReactPlayer
                ref={playerRef}
                url={videoUrl}
                playing={!stopVideo}
                muted={muteVideo}
                width={'100%'}
                height={'90vh'}
                onEnded={() => setStopVideo(true)}
                onProgress={handleProgress}
              />

              <>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    background:
                      stopVideo || overPlayer
                        ? 'rgba(0,0,0,0.4)'
                        : 'transparent',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    zIndex: 1,
                  }}
                >
                  <Grid container direction="column">
                    <Grid item />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      style={
                        isMobile
                          ? { marginTop: 20, display: 'none' }
                          : { marginTop: 20 }
                      }
                    >
                      {stopVideo ? (
                        <PlayButton handleClick={playButtonClick} />
                      ) : (
                        overPlayer && (
                          <PauseButton handleClick={playButtonClick} />
                        )
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={isMobile ? 'row' : 'column'}
                    style={
                      isMobile
                        ? {
                            padding: 10,
                            justifyContent: 'center',
                            paddingBottom: '4rem',
                          }
                        : { paddingLeft: '2rem', paddingBottom: 30 }
                    }
                  >
                    {!isMobile &&
                      (overPlayer || stopVideo ? (
                        !isHome && (
                          <>
                            <Grid item style={{ paddingLeft: 30 }}>
                              <h3>{course.name}</h3>
                            </Grid>
                            <Grid item style={{ paddingLeft: 30 }}>
                              <h5>
                                {course.partners &&
                                  course.partners[0] &&
                                  course.partners[0].fullname}
                              </h5>
                            </Grid>
                          </>
                        )
                      ) : (
                        <Grid item />
                      ))}
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      style={{ marginTop: 25, width: 'auto' }}
                    >
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          style={
                            isMobile
                              ? {
                                  width: '100%',
                                  flexWrap: 'nowrap',
                                }
                              : { paddingLeft: 30 }
                          }
                        >
                          {(!isMobile && overPlayer) || stopVideo ? (
                            !isHome && (
                              <>
                                <Grid
                                  item
                                  className="tabs-menu"
                                  style={{ marginRight: 15 }}
                                >
                                  <h4
                                    onClick={() => {
                                      setTab(0, 'about');
                                      showVideoPlayer();
                                    }}
                                  >
                                    {i18n.t('ABOUT')}
                                  </h4>
                                </Grid>
                                <Grid
                                  item
                                  className="tabs-menu"
                                  style={{ marginRight: 15 }}
                                >
                                  <h4
                                    onClick={() => {
                                      setTab(1, 'program');
                                      showVideoPlayer();
                                    }}
                                  >
                                    {i18n.t('CONTENT')}
                                  </h4>
                                </Grid>
                                <Grid
                                  item
                                  className="tabs-menu"
                                  style={{ marginRight: 15 }}
                                >
                                  <h4
                                    onClick={() => {
                                      setTab(2, 'mentor');
                                      showVideoPlayer();
                                    }}
                                  >
                                    {i18n.t('MENTOR')}
                                  </h4>
                                </Grid>
                                <Grid
                                  item
                                  className="tabs-menu"
                                  style={{ marginRight: 15 }}
                                >
                                  <h4
                                    onClick={() => {
                                      setTab(3, 'faqs');
                                      showVideoPlayer();
                                    }}
                                  >
                                    {i18n.t('FAQS')}
                                  </h4>
                                </Grid>
                                <Grid
                                  item
                                  className="tabs-menu"
                                  style={{ marginRight: 15 }}
                                >
                                  <h4
                                    onClick={() => {
                                      setTab(5, 'full-program');
                                      showVideoPlayer();
                                    }}
                                  >
                                    {i18n.t('COURSE')}
                                  </h4>
                                </Grid>
                              </>
                            )
                          ) : (
                            <Grid item />
                          )}
                        </Grid>
                      </Grid>

                      {((!isMobile && overPlayer) || stopVideo) && (
                        <Grid
                          container
                          direction="row"
                          style={{
                            marginBottom: 25,
                            width: 'auto',
                            marginRight: 20,
                          }}
                        >
                          <Grid item style={{ marginRight: 10 }}>
                            {stopVideo ? (
                              <PlayButton handleClick={playButtonClick} />
                            ) : (
                              <PauseButton handleClick={playButtonClick} />
                            )}
                          </Grid>
                          <Grid item style={{ marginRight: 10 }}>
                            {muteVideo ? (
                              <MuteButton handleClick={muteButtonClick} />
                            ) : (
                              <UnmuteButton handleClick={muteButtonClick} />
                            )}
                          </Grid>
                          <Grid item style={{ marginRight: 10 }}>
                            <CloseButton handleClick={showVideoPlayer} />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  container
                  direction="row"
                  style={{ padding: 0, marginTop: -10 }}
                >
                  {isMobile && (
                    <Grid
                      item
                      sm={1}
                      justifyContent="center"
                      style={{ paddingLeft: 5, marginTop: 20, zIndex: 1000 }}
                    >
                      {stopVideo ? (
                        <PlayButton
                          handleClick={playButtonClick}
                          isMobile={isMobile}
                        />
                      ) : (
                        <PauseButton
                          handleClick={playButtonClick}
                          isMobile={isMobile}
                        />
                      )}
                    </Grid>
                  )}
                  {(!isMobile && overPlayer) || stopVideo ? (
                    <Grid
                      item
                      xs={10}
                      sm={10}
                      md={12}
                      style={isMobile ? { paddingLeft: 12, marginTop: 15 } : {}}
                    >
                      <PrettoSlider
                        min={0}
                        max={100}
                        value={played * 100}
                        onChange={handleSeekChange}
                        onMouseDown={handleSeekMouseDown}
                        onChangeCommitted={handleSeekMouseUp}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={10}
                      sm={10}
                      md={12}
                      style={
                        isMobile
                          ? { paddingLeft: 12, marginTop: 15, height: 55 }
                          : {}
                      }
                    >
                      <div style={{ height: 25 }} />
                    </Grid>
                  )}
                  {isMobile && (
                    <Grid
                      item
                      sm={1}
                      style={{ paddingLeft: '4%', marginTop: 20, zIndex: 1000 }}
                    >
                      <FullScreenButton handleClick={showVideoPlayer} />
                    </Grid>
                  )}
                </Grid>
              </>
            </div>
          </Grid>
        </div>
      )}
    </Container>
  );
};

export default FullScreenPlayer;
