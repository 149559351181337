import api from '../../api';
import {
  GET_EDUCATIONALRESOURCES_REQUEST,
  GET_EDUCATIONALRESOURCES_SUCCESS,
  GET_EDUCATIONALRESOURCES_ERROR,
} from '../constants';

export const getEducationalResources = (query) => (dispatch) => {
  dispatch({
    type: GET_EDUCATIONALRESOURCES_REQUEST,
    meta: api.educationalresources
      .getEducationalResources(query)
      .then((response) => {
        dispatch({
          type: GET_EDUCATIONALRESOURCES_SUCCESS,
          payload: {
            data: response.data,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: GET_EDUCATIONALRESOURCES_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default { getEducationalResources };
