import api from '../../api';
import {
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_ERROR,
  GET_BANNER_BY_ID_REQUEST,
  GET_BANNER_BY_ID_SUCCESS,
  GET_BANNER_BY_ID_ERROR,
} from '../constants';

export const getBanners = (query) => (dispatch) => {
  dispatch({
    type: GET_BANNERS_REQUEST,
    meta: api.banners
      .getBanners(query)
      .then((response) =>
        dispatch({
          type: GET_BANNERS_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BANNERS_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export const getBannerById = (id) => (dispatch) => {
  dispatch({
    type: GET_BANNER_BY_ID_REQUEST,
    meta: api.banners
      .getBannerById(id)
      .then((response) =>
        dispatch({
          type: GET_BANNER_BY_ID_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BANNER_BY_ID_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default { getBanners, getBannerById };
