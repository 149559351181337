/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getBanners = (query) =>
  axios.get(`${config.CMS_API}/banners${query}`);

export const getBannerById = (id) =>
  axios.get(`${config.CMS_API}/banners/${id}`);

export default { getBanners, getBannerById };
