import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import './index.scss';
import { Grid, Modal } from '@material-ui/core';
import i18n from '../../../../languages';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '80%',
    maxWidth: 630,
    boxShadow: theme.shadows[5],
    padding: 20,
  },
}));

const PaymentMethodModal = ({ open, handleClose, handleSelection }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <h2>
            {i18n.t('MODAL_PAYMENT_METHOD_TITLE')}
            <IconButton onClick={handleClose} className="close">
              <CloseIcon />
            </IconButton>
          </h2>
        </Grid>
      </Grid>
      <Grid container className="form-content">
        <Grid item xs={12}>
          <p>{i18n.t('MODAL_PAYMENT_METHOD_DESCRIPTION')}</p>
        </Grid>
      </Grid>
      <Grid
        container
        className="container-border"
        direction="row"
        justifyContent="center"
      >
        <Grid
          item
          columnSpacing={{ xs: 12, sm: 5 }}
          className="box-button-left"
          onClick={() => {
            handleSelection('cc');
          }}
        >
          <div className="container-border-dark">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2580_24944)">
                <path
                  d="M5.7247 5.80396C4.27892 5.80396 3.09375 7.00229 3.09375 8.46414V15.4215C3.09375 16.8834 4.27892 18.0817 5.7247 18.0817H18.2723C19.7181 18.0817 20.9033 16.8834 20.9033 15.4215V8.46414C20.9033 7.00229 19.7181 5.80396 18.2723 5.80396H5.7247ZM5.7247 7.03173H18.2723C19.0616 7.03173 19.689 7.66606 19.689 8.46414V9.07803H4.30804V8.46414C4.30804 7.66606 4.93539 7.03173 5.7247 7.03173ZM4.30804 10.7151H19.689V15.4215C19.689 16.2196 19.0616 16.854 18.2723 16.854H5.7247C4.93539 16.854 4.30804 16.2196 4.30804 15.4215V10.7151ZM15.0342 13.9891C14.9538 13.988 14.8739 14.003 14.7992 14.0334C14.7246 14.0637 14.6566 14.1087 14.5993 14.1658C14.542 14.223 14.4965 14.291 14.4655 14.3661C14.4344 14.4411 14.4184 14.5217 14.4184 14.603C14.4184 14.6844 14.4344 14.7649 14.4655 14.84C14.4965 14.915 14.542 14.9831 14.5993 15.0402C14.6566 15.0973 14.7246 15.1424 14.7992 15.1727C14.8739 15.203 14.9538 15.2181 15.0342 15.2169H17.4628C17.5433 15.2181 17.6231 15.203 17.6978 15.1727C17.7724 15.1424 17.8404 15.0973 17.8977 15.0402C17.955 14.9831 18.0005 14.915 18.0315 14.84C18.0626 14.7649 18.0786 14.6844 18.0786 14.603C18.0786 14.5217 18.0626 14.4411 18.0315 14.3661C18.0005 14.291 17.955 14.223 17.8977 14.1658C17.8404 14.1087 17.7724 14.0637 17.6978 14.0334C17.6231 14.003 17.5433 13.988 17.4628 13.9891H15.0342Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_2580_24944">
                  <rect
                    width="19.4286"
                    height="13.6"
                    fill="white"
                    transform="translate(2.28516 5.14282)"
                  />
                </clipPath>
              </defs>
            </svg>

            <span>{i18n.t('MODAL_PAYMENT_METHOD_CC')}</span>
            <span></span>
          </div>
        </Grid>

        <Grid
          item
          columnSpacing={{ xs: 12, sm: 5 }}
          className="box-button-right"
          onClick={() => handleSelection('bt')}
        >
          <div className="container-border-dark">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.08911 11.2226H6.5252V16.8402H8.08911V11.2226ZM12.7808 11.2226H11.2169V16.8402H12.7808V11.2226ZM19.4275 18.4452H4.57031V20.0503H19.4275V18.4452ZM17.4726 11.2226H15.9087V16.8402H17.4726V11.2226ZM11.9989 5.81368L16.0729 8.01257H7.9249L11.9989 5.81368ZM11.9989 4L4.57031 8.01257V9.6176H19.4275V8.01257L11.9989 4Z"
                fill="black"
              />
            </svg>
            <span>{i18n.t('MODAL_PAYMENT_METHOD_BT')}</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal className="payment-method-modal" open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};

PaymentMethodModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

PaymentMethodModal.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default PaymentMethodModal;
