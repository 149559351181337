export const config = {
  URL_SITE_DOMAIN: process.env.REACT_APP_URL_SITE_DOMAIN,
  URL_EDX_DOMAIN: process.env.REACT_APP_URL_EDX_DOMAIN,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  COMMUNITY_URL: process.env.REACT_APP_COMMUNITY_URL,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  GOOGLE_ANALYTICS_TRACKING_CODE:
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
  GOOGLE_TAG_MANAGER_GTM_AUTH:
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_GTM_AUTH,
  GOOGLE_TAG_MANAGER_GTM_PREVIEW:
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_GTM_PREVIEW,
  GOOGLE_TAG_MANAGER_GTM_CODE:
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_GTM_CODE,
  CMS_API: process.env.REACT_APP_CMS_API,
};

export default { config };
