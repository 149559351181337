/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import psl from 'psl';
import lodash from 'lodash';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../languages';
import { getCourse } from '../../redux/actions/courses';
import { getFaqs } from '../../redux/actions/faqs';
import api from '../../api';
import trackAction from '../../utils/tracking';
import { isLocalhost } from '../../serviceWorker';
import {
  getReferrer,
  getUtmParams,
  getVariablesToSend,
} from '../../utils/getUtmParams';

import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import Loader from '../../components/Common/Loader';
import Jumbotron from '../../components/EducationalResource/Jumbotron';
import ActionBar from '../../components/EducationalResource/ActionBar';
import CourseAbout from '../../components/EducationalResource/CourseAbout';
import CourseProgram from '../../components/EducationalResource/CourseProgram';
import MoreCourses from '../../components/EducationalResource/MoreCourses';
import VerifySiteWide from '../../components/Common/VerifySiteWide';
import CoursePartner from '../../components/EducationalResource/CoursePartner';
import MetaTags from '../../components/Common/MetaTags';
import BuyButton from '../../components/Common/BuyButton';
import EarlyBidModal from '../../components/Common/Modals/EarlyBid';

import { courseType } from '../../types/coursesType';
import { degreeType } from '../../types/degreeType';
import { educationalResourcesType } from '../../types/educationalResourceType';
import menuType from '../../types/menuType';
import { userType } from '../../types/userType';

import './index.scss';
import Faqs from '../../components/EducationalResource/Faqs';
import FullCourseProgram from '../../components/EducationalResource/FullCourseProgram';

import RequestInfo from '../../components/Common/Modals/RequestInfo';
import FullScreenPlayer from '../../components/Common/FullScreenPlayer';
import CourseStartCountdown from '../../components/EducationalResource/CourseAbout/CourseStartCountdown';
import PriceContent from '../../components/EducationalResource/CourseAbout/PriceContent';
import ShareModal from '../../components/Common/Modals/Share';
import courseData from '../../constants/courses/es/courses';
import screenfull from 'screenfull';
import { Widget } from '@typeform/embed-react';
import { config } from '../../config/config';

const Course = (props) => {
  const { course: id, token } = props.match.params;

  const faqs = props.faqs;

  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openEarlyBidModal, setOpenEarlyBidModal] = useState(false);
  const [playerVisible, setPlayerVisible] = useState(false);

  const [openRequestInfoModal, setOpenRequestInfoModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [sectionClicked, setSectionClicked] = useState(false);
  const [tagClicked, setTagClicked] = useState(null);
  const listInnerRef = useRef();
  const [scrollReach, setScrollReach] = useState(false);

  const playerContainerRef = useRef(null);

  const [showTf, setShowTf] = useState(false);

  const domain = isLocalhost
    ? 'localhost'
    : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const currentUtm =
      Cookies.get('utm_params') && JSON.parse(Cookies.get('utm_params'));
    const currentReferrer =
      Cookies.get('referrer') && JSON.parse(Cookies.get('referrer'));

    const newUtm = getUtmParams();
    const newReferrer = getReferrer() && { referrer: getReferrer() };

    if (!lodash.isEmpty(newUtm) && currentUtm !== newUtm) {
      Cookies.set('utm_params', JSON.stringify(newUtm), {
        domain,
        expires: 7,
      });

      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }

    if (!lodash.isEmpty(newReferrer)) {
      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }

    let queryParams = window.location.href.indexOf('?tf=');
    if (queryParams !== -1) {
      setShowTf(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await props.actions.getCourse(id);
      await props.actions.getFaqs(`?locale=${i18n._culture}`);
    })();
  }, [props.actions, id]);

  if (token) {
    window.localStorage.setItem('mail_validated', 'true');

    api.marketingEmail
      .confirmMarketingEmail({
        email:
          window.localStorage.getItem('mail') &&
          window.localStorage.getItem('mail').replace(/['"]+/g, ''),
        token,
      })
      .catch((responseError) => {
        // eslint-disable-next-line no-console
        console.log(responseError, 'Error');
        return false;
      });
  }

  const { course, user } = props;

  // Tracking
  !course.loading && trackAction('view_item', [course]);

  const renderImagen = (media) => (
    <>
      <div className="background-top" />
      <Fade duration={1000}>
        <div
          className="home-background course"
          style={
            !isMobile
              ? media && {
                  backgroundImage: `url(${
                    isMobile ? media.square : media.wide
                  })`,
                }
              : media && {
                  backgroundImage: `url(${
                    isMobile ? media.square : media.wide
                  })`,
                }
          }
        />
      </Fade>
    </>
  );

  useEffect(() => {
    if (playerVisible) {
      screenfull.toggle(playerContainerRef.current);
      document.body.style.overflow = 'hidden';
      //setFullScreen(true)
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [playerVisible]);

  const showVideoPlayer = () => {
    if (playerVisible) {
      //videoPlayer.exit();
      if (isMobile) {
        window.screen.orientation.unlock();
      }
    } else {
      //videoPlayer.enter();
      if (isMobile) {
        screen.orientation
          .lock('landscape')
          .then(function () {
            console.log('locked');
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    setPlayerVisible(!playerVisible);
  };

  const handleClickSection = (tag) => {
    setSelectedTab(5);
    setSectionClicked(true);
    setTagClicked(tag);
  };

  const handleFullProgram = () => {
    setSelectedTab(5);
    setSectionClicked(true);
    if (isMobile) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 800);
    }
  };

  const handleBackToResume = () => {
    setSelectedTab(0);
    if (isMobile) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 800);
    }
  };

  const onScroll = () => {
    if (
      document.body.scrollTop > 740 ||
      document.documentElement.scrollTop > 740
    ) {
      setScrollReach(true);
    } else {
      setScrollReach(false);
    }
  };

  const handleSelection = (selection) => {
    if (selection === 'cc') {
      const params = getVariablesToSend() && `&${getVariablesToSend()}`;
      const pricingUrl =
        course.pricing.length > 0 && `${course.pricing[0].url}${params}`;
      if (user && user.logged) {
        window.location.href = pricingUrl;
      } else {
        window.location.href = `https:${config.URL_EDX_DOMAIN}/login?next=${pricingUrl}`;
      }
    } else {
      if (user && user.logged) {
        setShowTf(true);
      } else {
        const currentCourse = window.location.href + '?tf=true';
        window.location.href = `https:${config.URL_EDX_DOMAIN}/login?next=${currentCourse}`;
      }
    }
  };

  const getUserPayload = () => {
    const cookie = Cookies.get();
    const userInfo =
      cookie && cookie['edx-user-info']
        ? cookie['edx-user-info'] &&
          JSON.parse(
            cookie['edx-user-info'].replace(/\\054/g, ',').replace(/\\/g, ''),
          )
        : {};

    let currentUrl = window.location.href.split('?')[0];
    let urlRedirect = currentUrl.replace(
      `https://${config.URL_SITE_DOMAIN}/`,
      '',
    );

    return {
      course: course.name,
      date: new Date().toISOString().split('T')[0],
      email:
        userInfo !== null && userInfo.hasOwnProperty('email')
          ? userInfo.email
          : '-',
      name:
        userInfo !== null && userInfo.hasOwnProperty('fullname')
          ? userInfo.fullname
          : '-',
      redirect: urlRedirect,
    };
  };

  return (
    <>
      <MetaTags {...course} />
      <VerifySiteWide user={user} idContent={course.id} />

      {showTf ? (
        <Widget
          id="PTagTLcD"
          style={{ width: '100%', height: '100%' }}
          onReady={() => {
            console.log(getUserPayload());
          }}
          onClose={() => window.location.reload()}
          onSubmit={() => {
            /*const url = window.location.href.split('?')[0];
            window.location.href = url;*/
          }}
          hidden={getUserPayload()}
        />
      ) : (
        <>
          <div className="page-wrap">
            {!playerVisible && (
              <Header
                page="course"
                isMobile={isMobile}
                educationalResource={course}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                scrollReach={scrollReach}
              />
            )}

            {course.loading ? (
              <Loader className="no-header" />
            ) : (
              <>
                {renderImagen(course.media)}

                <div className="page course" onTouchMove={onScroll}>
                  {!playerVisible ? (
                    <Container maxWidth="lg" className="jumbo-container">
                      <Jumbotron
                        type={i18n.t('COURSE')}
                        name={course.name}
                        author={course.org}
                        partners={course.partners}
                        short_description={course.short_description}
                        id={course.id}
                        user={user}
                        sponsors={course.sponsors}
                        educationalResource={course}
                        isMobile={isMobile}
                        handlePlayVideo={showVideoPlayer}
                        handleSelection={handleSelection}
                      />
                    </Container>
                  ) : (
                    <div ref={playerContainerRef}>
                      <FullScreenPlayer
                        isMobile={isMobile}
                        course={course}
                        showVideoPlayer={showVideoPlayer}
                        setSelectedTab={(tab, divId) => {
                          setSelectedTab(tab);
                          if (tab < 5) {
                            document.getElementById(divId).scrollIntoView();
                          }
                        }}
                      />
                    </div>
                  )}

                  <div className="action-bar-container">
                    <Container maxWidth="lg">
                      <Grid container direction="row">
                        <Grid item style={{ marginTop: -5 }} sm={8}>
                          <ActionBar
                            isMobile={isMobile}
                            pricing={course.pricing}
                            type="course"
                            selectedTab={selectedTab}
                            setSelectedTab={(tab) => {
                              setSelectedTab(tab);
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          className="buy-button-actionbar"
                          style={{ marginLeft: 'auto' }}
                          sm={3}
                        >
                          <BuyButton
                            available_language={course.available_language}
                            educationalResource={course}
                            language={course.language}
                            pricing={course.pricing}
                            status={course.status}
                            id={course.id}
                            openDescription
                            user={user}
                            handleSelection={handleSelection}
                          />
                        </Grid>
                      </Grid>
                    </Container>
                  </div>

                  {selectedTab !== 5 && (
                    <div ref={listInnerRef}>
                      <a
                        className="anchor"
                        name="about"
                        id="about"
                        href="#about"
                        style={{ height: 0 }}
                      >
                        About
                      </a>
                      <CourseAbout
                        course={course}
                        user={user}
                        isMobile={isMobile}
                        courseData={courseData}
                      />
                      <a
                        className="anchor"
                        name="program"
                        id="program"
                        href="#program"
                      >
                        Program
                      </a>
                      <CourseProgram
                        structure={course.structure}
                        user={user}
                        other_settings={course.other_settings}
                        id={course.id}
                        status={course.status}
                        handleClickSection={handleClickSection}
                        handleFullProgram={handleFullProgram}
                      />
                      <a
                        className="anchor"
                        name="mentor"
                        id="mentor"
                        href="#mentor"
                      >
                        Mentor
                      </a>
                      {isMobile && (
                        <Grid
                          item
                          sm={4}
                          md={3}
                          style={{ width: '100%', padding: 16 }}
                        >
                          {user.logged &&
                            course.bought &&
                            course.runs_dates &&
                            course.runs_dates[0] && (
                              <CourseStartCountdown
                                start={course.runs_dates[0].start}
                              />
                            )}
                          <PriceContent {...course} />
                          <Grid container style={{ marginBottom: 20 }}>
                            <div
                              className="btn-black share-button block"
                              style={{ width: '100%', textAlign: 'center' }}
                              onClick={(event) => {
                                event.preventDefault();
                                setOpenModal(true);
                              }}
                            >
                              {i18n.t('SHARE_COURSE_PROGRAM')}
                            </div>
                            <ShareModal
                              open={openModal}
                              handleClose={() => setOpenModal(false)}
                              idContent={course.id}
                              name={course.name}
                              description={course.short_description}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Container maxWidth="xl" style={{ padding: 0 }}>
                        <CoursePartner slider={course.partners} fixed />
                      </Container>

                      <a className="anchor" name="faqs" id="faqs" href="#faqs">
                        Faqs
                      </a>
                      {faqs?.faqs.length > 0 && (
                        <Faqs faqs={faqs.faqs} course={course} />
                      )}
                      <div className="question-section">
                        <Container maxWidth="lg" className="container-question">
                          <Grid container direction="row">
                            <Grid item sm={1} />
                            <Grid
                              item
                              xs={12}
                              sm={9}
                              className="content-question"
                            >
                              <h4>{i18n.t('HAVE_QUESTION')}</h4>
                              <p>{i18n.t('PRESS_BUTTON_QUESTION')}</p>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <button
                                className="btn-primary md arrow-right"
                                style={
                                  isMobile
                                    ? { width: '100%' }
                                    : {
                                        float: 'right',
                                        minWidth: 183,
                                        textAlign: 'center',
                                      }
                                }
                                onClick={() => setOpenRequestInfoModal(true)}
                              >
                                {i18n.t('CONSULT')}
                              </button>
                            </Grid>
                          </Grid>
                        </Container>
                      </div>
                    </div>
                  )}

                  {selectedTab === 5 && (
                    <>
                      <div className="program-section">
                        <FullCourseProgram
                          course={course}
                          user={user}
                          other_settings={course.other_settings}
                          id={course.id}
                          status={course.status}
                          sectionClicked={sectionClicked}
                          tag={tagClicked}
                          handleBackToResume={handleBackToResume}
                        />
                      </div>
                    </>
                  )}

                  <MoreCourses courses={course.related_contents} limit={2} />
                </div>
              </>
            )}
          </div>

          <EarlyBidModal
            open={openEarlyBidModal}
            handleClose={(e) => {
              e.preventDefault();
              setOpenEarlyBidModal(false);
              Cookies.set('early-access', 'false', {
                domain,
              });
            }}
          />

          <RequestInfo
            open={openRequestInfoModal}
            handleClose={(e) => {
              e.preventDefault();
              setOpenRequestInfoModal(false);
            }}
          />
          <Footer className="home" user={user} />
        </>
      )}
    </>
  );
};

Course.propTypes = {
  actions: PropTypes.shape({
    getCourse: PropTypes.func,
    getFaqs: PropTypes.func,
  }),
  course: courseType,
  degree: degreeType,
  enrollments: educationalResourcesType,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  menu: menuType,
  search: educationalResourcesType,
  staticContext: PropTypes.object,
  user: userType,
};

Course.defaultProps = {
  actions: {
    getCourse: () => {},
    getFaqs: () => {},
  },
  course: {},
  degree: {},
  enrollments: [],
  menu: [],
  search: [],
  staticContext: {},
  user: {},
  faqs: {},
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getCourse: bindActionCreators(getCourse, dispatch),
    getFaqs: bindActionCreators(getFaqs, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Course);
