/* eslint-disable react/no-danger */
/* eslint-disable global-require */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';

import Grid from '@material-ui/core/Grid';

import EnterEmailModal from '../../Common/Modals/EnterEmail';

import './index.scss';

const echoImage = (image) => {
  try {
    return require(`../../../assets/images/content_info/${image}`).default;
  } catch (e) {
    return false;
  }
};

const ContentInfo = ({ content_info }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="content-info">
      <a className="anchor" name="community" id="community" href="#community">
        Community
      </a>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className="grid-container"
        spacing={2}
      >
        {content_info.map(
          (item, index) =>
            item.attributes &&
            item.attributes?.title === 'Community<br />Hub' && (
              <Grid item sm={12} key={lodash.uniqueId('content_info_')}>
                <Fade duration={500}>
                  <div
                    style={{
                      backgroundImage: `url(${item.attributes?.image?.data?.attributes?.url}`,
                    }}
                    className="item"
                  >
                    <div
                      className={classnames(
                        'box-content',
                        index % 2 === 0 ? 'left' : 'right',
                        item.link !== '' && 'with-link',
                      )}
                    >
                      <Grid
                        container
                        spacing={2}
                        className="box-content-container"
                        justifyContent={
                          index % 2 === 0 ? 'flex-end' : 'flex-start'
                        }
                      >
                        <Grid item sm={1} md={1} />
                        <Grid
                          item
                          sm={10}
                          md={4}
                          className="content-info-detail"
                        >
                          <div className="content-info-detail-container">
                            <h2
                              dangerouslySetInnerHTML={{
                                __html: item.attributes?.title?.replace(
                                  /(.-)/g,
                                  '$1<br />',
                                ),
                              }}
                            />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.attributes?.content,
                              }}
                            />
                            {item.attributes?.title &&
                              item.attributes?.title !==
                                'Community<br />Hub' && (
                                <a
                                  className="btn-primary content-button arrow-right md"
                                  href={item.attributes?.link}
                                >
                                  {item.attributes?.label_link}
                                </a>
                              )}
                          </div>

                          {item.attributes?.title &&
                            item.attributes?.title === 'Community<br />Hub' && (
                              <button
                                type="button"
                                className="btn-primary content-button arrow-right md"
                                onClick={setOpenModal}
                              >
                                {item.attributes?.label_link}
                              </button>
                            )}
                        </Grid>
                        <Grid item sm={1} />
                        <EnterEmailModal
                          open={openModal}
                          handleClose={() => setOpenModal(false)}
                          idContent="community"
                        />
                      </Grid>
                    </div>
                  </div>
                </Fade>
              </Grid>
            ),
        )}
      </Grid>
    </div>
  );
};

ContentInfo.propTypes = {
  content_info: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      link_description: PropTypes.string,
    }),
  ),
};

ContentInfo.defaultProps = {
  content_info: [],
};

export default ContentInfo;
