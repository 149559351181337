/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../languages';

import Header from '../../../components/Common/Header';
import Footer from '../../../components/Common/Footer';
import MetaTags from '../../../components/Common/MetaTags';

import { getPageBySlug } from '../../../redux/actions/pages';

import '../index.scss';

const CookiesPolicy = (props) => {
  const { actions, pages } = props;
  const { pathname } = useLocation();
  const { lang } = useParams();
  const [content, setContent] = useState('');

  const langs = [{ en: '/info/cookies' }, { es: '/es/info/cookies' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (lang) {
      actions.getPageBySlug(`politicas-de-cookies?locale=${lang}`);
    } else {
      actions.getPageBySlug(`politicas-de-cookies?locale=en`);
    }
  }, [lang]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_PRIVA_TITLE')}
        description={i18n.t('PAGE_PRIVA_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page privacy">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>{pages.page.attributes?.title}</h2>
              <p></p>
              <div
                dangerouslySetInnerHTML={{
                  __html: pages.page.attributes?.content,
                }}
              />
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

CookiesPolicy.propTypes = {
  pages: PropTypes.object,
};

CookiesPolicy.defaultProps = {
  pages: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pages: state.pages,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPageBySlug: bindActionCreators(getPageBySlug, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CookiesPolicy);
