import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../languages';

import Grid from '@material-ui/core/Grid';

import './index.scss';

const CourseContent = (props) => {
  return (
    <div className="course-content">
      <Grid container>
        <Grid item xs={12} className="content">
          <h3
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: props.name }}
          />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: props.overview }}
          />
          <h3>{i18n.t('WHAT_ARE_YOU_GOING_TO_LEARN')}</h3>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: props.what_will_you_learn }}
          />
          <h3>{i18n.t('SKILLS_YOU_ACQUIRE')}</h3>
          <div
            className="skills-you-acquire"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: props.skills_you_acquire }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

CourseContent.propTypes = {
  overview: PropTypes.string,
};

CourseContent.defaultProps = {
  overview: '',
};

export default CourseContent;
