import * as courses from './courses/index';
import * as banners from './banners/index';
import * as biographies from './biographies/index';
import * as degrees from './degrees/index';
import * as educationalresources from './educationalresources/index';
import * as enrollments from './enrollments/index';
import * as marketingEmail from './marketingEmail/index';
import * as menu from './menu/index';
import * as partners from './partners/index';
import * as search from './search/index';
import * as pages from './pages/index';
import * as faqs from './faqs/index';

const api = {
  banners,
  biographies,
  courses,
  degrees,
  educationalresources,
  enrollments,
  marketingEmail,
  menu,
  partners,
  search,
  pages,
  faqs,
};

export default api;
