import api from '../../api';
import {
  GET_BIOGRAPHIES_REQUEST,
  GET_BIOGRAPHIES_SUCCESS,
  GET_BIOGRAPHIES_ERROR,
  GET_BIOGRAPHY_BY_ID_REQUEST,
  GET_BIOGRAPHY_BY_ID_SUCCESS,
  GET_BIOGRAPHY_BY_ID_ERROR,
} from '../constants';

export const getBiographies = (query) => (dispatch) => {
  dispatch({
    type: GET_BIOGRAPHIES_REQUEST,
    meta: api.biographies
      .getBiographies(query)
      .then((response) =>
        dispatch({
          type: GET_BIOGRAPHIES_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BIOGRAPHIES_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export const getBiographyById = (id, lang) => (dispatch) => {
  dispatch({
    type: GET_BIOGRAPHY_BY_ID_REQUEST,
    meta: api.biographies
      .getBiographyById(id, lang)
      .then((response) =>
        dispatch({
          type: GET_BIOGRAPHY_BY_ID_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_BIOGRAPHY_BY_ID_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default { getBiographies, getBiographyById };
