/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
// import { isMobile } from 'react-device-detect';

import Grid from '@material-ui/core/Grid';

import './index.scss';

const AboutBox = ({ title, description, content, image, link, linkCta }) => {
  // const filename = isMobile ? image.replace(/(\.[\w\d_-]+)$/i, '_mobile$1') : image;
  const filename = image?.data?.attributes?.url;

  const subtitle = content ? content.split('"')[0] : '';

  const newContent = content ? content.split('"')[1] : '';

  return (
    <div className="about-box">
      <div
        style={{
          backgroundImage: `url(${filename}`,
        }}
        className="about-item"
      >
        <div className="box-content">
          <Grid container spacing={0} className="box-content-container">
            <Grid item sm={1} md={1} />
            <Grid item sm={5} md={4} className="about-content">
              <a href={link}>
                <h2>{title}</h2>
                <h3>{subtitle}</h3>

                <div className="non-over">
                  <h4>
                    &quot;
                    {newContent}
                    &quot;
                  </h4>

                  <div className="btn-white arrow-right md read-more">
                    {linkCta}
                  </div>
                </div>
              </a>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

AboutBox.propTypes = {
  fullname: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
};

AboutBox.defaultProps = {
  fullname: '',
  description: '',
  content: '',
  image: '',
  link: '',
};

export default AboutBox;
