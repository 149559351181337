import React, { useState } from 'react';
import './index.scss';

const MuteButton = ({ handleClick }) => {
  const [hover, setHover] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  };

  return (
    <div className="mute-button">
      <a
        onClick={handleButtonClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover ? (
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 18.5C0 8.28024 8.28024 0 18.5 0C28.7198 0 37 8.28024 37 18.5C37 28.7198 28.7198 37 18.5 37C8.28024 37 0 28.7198 0 18.5ZM2.3871 18.5C2.3871 27.4442 9.64536 34.6129 18.5 34.6129C27.4442 34.6129 34.6129 27.3546 34.6129 18.5C34.6129 9.55585 27.3546 2.3871 18.5 2.3871C9.55585 2.3871 2.3871 9.64536 2.3871 18.5Z"
              fill="white"
              fillOpacity="0.7"
            />
            <path
              d="M10.6368 10C10.5101 10 10.3862 10.0379 10.2811 10.1087C10.176 10.1795 10.0943 10.2801 10.0467 10.3976C9.99906 10.515 9.98755 10.644 10.0136 10.7681C10.0397 10.8921 10.1022 11.0055 10.1931 11.0939L13.7766 14.6773H12.346C11.293 14.6773 10.4353 15.535 10.4353 16.588V20.4094C10.4353 21.4624 11.293 22.3201 12.346 22.3201H15.1565L19.0717 25.8016C19.2967 26.0011 19.5725 26.1034 19.857 26.1034C20.0226 26.1034 20.1883 26.0695 20.3496 25.9973C20.7827 25.802 21.0504 25.3855 21.0504 24.9142V21.9511L25.9035 26.8042C25.9622 26.8653 26.0325 26.9141 26.1102 26.9477C26.188 26.9813 26.2717 26.9991 26.3565 27C26.4412 27.0008 26.5253 26.9848 26.6037 26.9527C26.6822 26.9207 26.7534 26.8734 26.8133 26.8134C26.8733 26.7535 26.9206 26.6822 26.9527 26.6038C26.9847 26.5253 27.0007 26.4413 26.9999 26.3566C26.999 26.2718 26.9813 26.1881 26.9476 26.1103C26.914 26.0325 26.8652 25.9622 26.8041 25.9036L11.0938 10.1932C11.0344 10.1321 10.9634 10.0835 10.8849 10.0503C10.8064 10.0171 10.722 10 10.6368 10ZM19.857 10.894C19.5725 10.894 19.2925 10.9963 19.0717 11.1959L16.3333 13.6323L17.2331 14.533L19.7766 12.274V17.0764L21.0504 18.3503V12.0874C21.0504 11.6118 20.7827 11.1955 20.3496 11.0001C20.1883 10.928 20.0226 10.894 19.857 10.894ZM24.7192 13.406C24.6369 13.4126 24.5546 13.4353 24.4771 13.4756C24.1629 13.637 24.0437 14.0232 24.2051 14.3331C25.5341 16.8722 25.6576 19.3483 24.5791 21.8789L25.5386 22.8385C26.978 19.8195 26.9098 16.7623 25.3346 13.7476C25.2136 13.512 24.9662 13.386 24.7192 13.406ZM22.835 15.5315C22.752 15.5338 22.6678 15.5532 22.5871 15.5903C22.2686 15.7389 22.1292 16.1127 22.2736 16.4354C22.8001 17.5861 22.8887 18.7067 22.5448 19.8447L23.5383 20.8382C24.2177 19.1992 24.1837 17.5436 23.4321 15.9046C23.3239 15.6658 23.0842 15.5243 22.835 15.5315ZM12.346 15.9511H15.0504L19.7766 20.6773V24.7235L15.8191 21.208C15.7045 21.1019 15.5515 21.0463 15.3987 21.0463H12.346C11.9936 21.0463 11.7091 20.7618 11.7091 20.4094V16.588C11.7091 16.2356 11.9936 15.9511 12.346 15.9511Z"
              fill="#52e0ff"
            />
          </svg>
        ) : (
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 18.5C0 8.28024 8.28024 0 18.5 0C28.7198 0 37 8.28024 37 18.5C37 28.7198 28.7198 37 18.5 37C8.28024 37 0 28.7198 0 18.5ZM2.3871 18.5C2.3871 27.4442 9.64536 34.6129 18.5 34.6129C27.4442 34.6129 34.6129 27.3546 34.6129 18.5C34.6129 9.55585 27.3546 2.3871 18.5 2.3871C9.55585 2.3871 2.3871 9.64536 2.3871 18.5Z"
              fill="white"
              fillOpacity="0.7"
            />
            <path
              d="M10.6368 10C10.5101 10 10.3862 10.0379 10.2811 10.1087C10.176 10.1795 10.0943 10.2801 10.0467 10.3976C9.99906 10.515 9.98755 10.644 10.0136 10.7681C10.0397 10.8921 10.1022 11.0055 10.1931 11.0939L13.7766 14.6773H12.346C11.293 14.6773 10.4353 15.535 10.4353 16.588V20.4094C10.4353 21.4624 11.293 22.3201 12.346 22.3201H15.1565L19.0717 25.8016C19.2967 26.0011 19.5725 26.1034 19.857 26.1034C20.0226 26.1034 20.1883 26.0695 20.3496 25.9973C20.7827 25.802 21.0504 25.3855 21.0504 24.9142V21.9511L25.9035 26.8042C25.9622 26.8653 26.0325 26.9141 26.1102 26.9477C26.188 26.9813 26.2717 26.9991 26.3565 27C26.4412 27.0008 26.5253 26.9848 26.6037 26.9527C26.6822 26.9207 26.7534 26.8734 26.8133 26.8134C26.8733 26.7535 26.9206 26.6822 26.9527 26.6038C26.9847 26.5253 27.0007 26.4413 26.9999 26.3566C26.999 26.2718 26.9813 26.1881 26.9476 26.1103C26.914 26.0325 26.8652 25.9622 26.8041 25.9036L11.0938 10.1932C11.0344 10.1321 10.9634 10.0835 10.8849 10.0503C10.8064 10.0171 10.722 10 10.6368 10ZM19.857 10.894C19.5725 10.894 19.2925 10.9963 19.0717 11.1959L16.3333 13.6323L17.2331 14.533L19.7766 12.274V17.0764L21.0504 18.3503V12.0874C21.0504 11.6118 20.7827 11.1955 20.3496 11.0001C20.1883 10.928 20.0226 10.894 19.857 10.894ZM24.7192 13.406C24.6369 13.4126 24.5546 13.4353 24.4771 13.4756C24.1629 13.637 24.0437 14.0232 24.2051 14.3331C25.5341 16.8722 25.6576 19.3483 24.5791 21.8789L25.5386 22.8385C26.978 19.8195 26.9098 16.7623 25.3346 13.7476C25.2136 13.512 24.9662 13.386 24.7192 13.406ZM22.835 15.5315C22.752 15.5338 22.6678 15.5532 22.5871 15.5903C22.2686 15.7389 22.1292 16.1127 22.2736 16.4354C22.8001 17.5861 22.8887 18.7067 22.5448 19.8447L23.5383 20.8382C24.2177 19.1992 24.1837 17.5436 23.4321 15.9046C23.3239 15.6658 23.0842 15.5243 22.835 15.5315ZM12.346 15.9511H15.0504L19.7766 20.6773V24.7235L15.8191 21.208C15.7045 21.1019 15.5515 21.0463 15.3987 21.0463H12.346C11.9936 21.0463 11.7091 20.7618 11.7091 20.4094V16.588C11.7091 16.2356 11.9936 15.9511 12.346 15.9511Z"
              fill="white"
              fillOpacity="0.7"
            />
          </svg>
        )}
      </a>
    </div>
  );
};

export default MuteButton;
