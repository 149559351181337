import React, { useEffect, useState } from 'react';
import './index.scss';

import { biographyBodyType } from '../../../types/biographyType';
import { Grid } from '@material-ui/core';
import QuoteSection from './QuoteSection';
import TextSection from './TextSection';
import { isMobile } from 'react-device-detect';

const MentorBiographyBody = (props) => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const { quotes, texts } = props.biography;
    if (texts) {
      let newTexts = [...texts];
      let newQuotes = [...quotes];
      //const firstText = newTexts.shift();
      //const firstQuote = newQuotes.shift();
      let newContent = [];
      for (let i in newTexts) {
        const text = newTexts[i];
        const quote = newQuotes[i];
        if (quote) {
          newContent.push({ content: quote, type: 'quote' });
        }
        newContent.push({ content: text, type: 'text' });
      }
      setContent(newContent);
    }
  }, [props.biography]);

  return (
    <>
      {content.map((item, k) => {
        return (
          <div key={k}>
            {item.type === 'quote' ? (
              <QuoteSection quote={item.content} />
            ) : (
              <Grid container>
                {!isMobile && <Grid item xs={5}></Grid>}
                <Grid item xs={isMobile ? 12 : 7}>
                  <TextSection text={item.content?.text} />
                </Grid>
              </Grid>
            )}
          </div>
        );
      })}
    </>
  );
};

MentorBiographyBody.propTypes = {
  biography: biographyBodyType,
};

MentorBiographyBody.defaultProps = {
  biography: {},
};

export default MentorBiographyBody;
