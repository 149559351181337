/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import psl from 'psl';
import lodash, { first } from 'lodash';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../languages';
import { getBiographyById } from '../../redux/actions/biographies';
import api from '../../api';
import trackAction from '../../utils/tracking';
import { isLocalhost } from '../../serviceWorker';
import {
  getReferrer,
  getUtmParams,
  getVariablesToSend,
} from '../../utils/getUtmParams';

import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import Loader from '../../components/Common/Loader';
import Jumbotron from '../../components/Mentor/Jumbotron';
import ActionBar from '../../components/EducationalResource/ActionBar';
import CourseAbout from '../../components/EducationalResource/CourseAbout';
import CourseProgram from '../../components/EducationalResource/CourseProgram';
import MoreCourses from '../../components/EducationalResource/MoreCourses';
import VerifySiteWide from '../../components/Common/VerifySiteWide';
import CoursePartner from '../../components/EducationalResource/CoursePartner';
import MetaTags from '../../components/Common/MetaTags';
import BuyButton from '../../components/Common/BuyButton';
import EarlyBidModal from '../../components/Common/Modals/EarlyBid';

import { biographyType } from '../../types/biographyType';
import { degreeType } from '../../types/degreeType';
import { educationalResourcesType } from '../../types/educationalResourceType';
import menuType from '../../types/menuType';
import { userType } from '../../types/userType';

import './index.scss';
import Faqs from '../../components/EducationalResource/Faqs';
import FullCourseProgram from '../../components/EducationalResource/FullCourseProgram';

import RequestInfo from '../../components/Common/Modals/RequestInfo';
import FullScreenPlayer from '../../components/Common/FullScreenPlayer';
import CourseStartCountdown from '../../components/EducationalResource/CourseAbout/CourseStartCountdown';
import PriceContent from '../../components/EducationalResource/CourseAbout/PriceContent';
import ShareModal from '../../components/Common/Modals/Share';
import courseData from '../../constants/courses/es/courses';
import screenfull from 'screenfull';
import { Widget } from '@typeform/embed-react';
import { config } from '../../config/config';
import Chronology from '../../components/Mentor/Chronology';
import TextSection from '../../components/Mentor/MentorBiographyBody/TextSection';
import MentorBiographyBody from '../../components/Mentor/MentorBiographyBody';
import { getCoursesByMentor } from '../../redux/actions/courses';

const Mentor = (props) => {
  const { lang, slug, token } = props.match.params;

  const {
    biographies: { biography, loading },
    user,
    courses: { mentorCourses },
  } = props;

  const { pathname } = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openEarlyBidModal, setOpenEarlyBidModal] = useState(false);
  const [playerVisible, setPlayerVisible] = useState(false);
  const [biographyContent, setBiographyContent] = useState({});

  const [openRequestInfoModal, setOpenRequestInfoModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [sectionClicked, setSectionClicked] = useState(false);
  const [tagClicked, setTagClicked] = useState(null);
  const listInnerRef = useRef();
  const [scrollReach, setScrollReach] = useState(false);
  const [chronologyProps, setChronologyProps] = useState({});
  const [firstSectionText, setFirstSectionText] = useState('');
  const [relatedCourses, setRelateCourses] = useState([]);

  const playerContainerRef = useRef(null);

  const [showTf, setShowTf] = useState(false);

  const domain = isLocalhost
    ? 'localhost'
    : `.${psl.parse(process.env.REACT_APP_URL_SITE_DOMAIN).domain}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (biography && biography.attributes) {
      const { content, slug } = biography.attributes;
      if (content) {
        const { quotes, texts, quick_facts } = content;
        setBiographyContent(content);
        if (quick_facts) {
          setChronologyProps({ text: quick_facts });
        }
        if (texts && texts.length) {
          const text = texts[0].text ? texts[0].text : '';
          setFirstSectionText(text);
        }
      }
      if (slug) {
        actons;
      }
    }
  }, [biography]);

  useEffect(() => {
    setRelateCourses(mentorCourses);
  }, [mentorCourses]);

  useEffect(() => {
    const currentUtm =
      Cookies.get('utm_params') && JSON.parse(Cookies.get('utm_params'));
    const currentReferrer =
      Cookies.get('referrer') && JSON.parse(Cookies.get('referrer'));

    const newUtm = getUtmParams();
    const newReferrer = getReferrer() && { referrer: getReferrer() };

    if (!lodash.isEmpty(newUtm) && currentUtm !== newUtm) {
      Cookies.set('utm_params', JSON.stringify(newUtm), {
        domain,
        expires: 7,
      });

      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }

    if (!lodash.isEmpty(newReferrer)) {
      Cookies.set('referrer', JSON.stringify(newReferrer), {
        domain,
        expires: 7,
      });
    }

    let queryParams = window.location.href.indexOf('?tf=');
    if (queryParams !== -1) {
      setShowTf(true);
    }
  }, []);

  useEffect(() => {
    props.actions.getBiographyById(slug, lang).then((resp) => {
      return props.actions.getCoursesByMentor(slug);
    });
  }, [props.actions, slug, lang]);

  if (token) {
    window.localStorage.setItem('mail_validated', 'true');

    api.marketingEmail
      .confirmMarketingEmail({
        email:
          window.localStorage.getItem('mail') &&
          window.localStorage.getItem('mail').replace(/['"]+/g, ''),
        token,
      })
      .catch((responseError) => {
        // eslint-disable-next-line no-console
        console.log(responseError, 'Error');
        return false;
      });
  }

  // Tracking
  !loading && trackAction('view_item', [biography]);

  const renderImagen = (media) => (
    <>
      <div className="background-top" />
      <Fade duration={1000}>
        <div
          className="home-background course"
          style={
            !isMobile
              ? media && {
                  backgroundImage: `url(${media.data?.attributes?.url})`,
                }
              : media && {
                  backgroundImage: `url(${media.data?.attributes?.url})`,
                }
          }
        />
      </Fade>
    </>
  );

  useEffect(() => {
    if (playerVisible) {
      screenfull.toggle(playerContainerRef.current);
      document.body.style.overflow = 'hidden';
      //setFullScreen(true)
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [playerVisible]);

  const showVideoPlayer = () => {
    if (playerVisible) {
      //videoPlayer.exit();
      if (isMobile) {
        window.screen.orientation.unlock();
      }
    } else {
      //videoPlayer.enter();
      if (isMobile) {
        screen.orientation
          .lock('landscape')
          .then(function () {
            console.log('locked');
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    setPlayerVisible(!playerVisible);
  };

  const handleClickSection = (tag) => {
    setSelectedTab(5);
    setSectionClicked(true);
    setTagClicked(tag);
  };

  const handleFullProgram = () => {
    setSelectedTab(5);
    setSectionClicked(true);
    if (isMobile) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 800);
    }
  };

  const handleBackToResume = () => {
    setSelectedTab(0);
    if (isMobile) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 800);
    }
  };

  const onScroll = () => {
    if (
      document.body.scrollTop > 740 ||
      document.documentElement.scrollTop > 740
    ) {
      setScrollReach(true);
    } else {
      setScrollReach(false);
    }
  };

  const handleSelection = (selection) => {
    if (selection === 'cc') {
      const params = getVariablesToSend() && `&${getVariablesToSend()}`;
      const pricingUrl =
        biography.pricing.length > 0 && `${biography.pricing[0].url}${params}`;
      if (user && user.logged) {
        window.location.href = pricingUrl;
      } else {
        window.location.href = `https:${config.URL_EDX_DOMAIN}/login?next=${pricingUrl}`;
      }
    } else {
      if (user && user.logged) {
        setShowTf(true);
      } else {
        const currentCourse = window.location.href + '?tf=true';
        window.location.href = `https:${config.URL_EDX_DOMAIN}/login?next=${currentCourse}`;
      }
    }
  };

  const getUserPayload = () => {
    const cookie = Cookies.get();
    const userInfo =
      cookie && cookie['edx-user-info']
        ? cookie['edx-user-info'] &&
          JSON.parse(
            cookie['edx-user-info'].replace(/\\054/g, ',').replace(/\\/g, ''),
          )
        : {};

    let currentUrl = window.location.href.split('?')[0];
    let urlRedirect = currentUrl.replace(
      `https://${config.URL_SITE_DOMAIN}/`,
      '',
    );

    return {
      biography: biography.name,
      date: new Date().toISOString().split('T')[0],
      email:
        userInfo !== null && userInfo.hasOwnProperty('email')
          ? userInfo.email
          : '-',
      name:
        userInfo !== null && userInfo.hasOwnProperty('fullname')
          ? userInfo.fullname
          : '-',
      redirect: urlRedirect,
    };
  };

  return (
    <>
      <MetaTags {...biography} />
      <VerifySiteWide user={user} idContent={biography.id} />

      {showTf ? (
        <Widget
          id="PTagTLcD"
          style={{ width: '100%', height: '100%' }}
          onReady={() => {
            console.log(getUserPayload());
          }}
          onClose={() => window.location.reload()}
          onSubmit={() => {
            /*const url = window.location.href.split('?')[0];
            window.location.href = url;*/
          }}
          hidden={getUserPayload()}
        />
      ) : (
        <>
          <div className="page-wrap">
            {!playerVisible && (
              <Header
                page="mentor"
                isMobile={isMobile}
                educationalResource={biography}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                scrollReach={scrollReach}
              />
            )}

            {loading ? (
              <Loader className="no-header" />
            ) : (
              <>
                {renderImagen(biography.attributes?.main_image)}

                <div className="page course" onTouchMove={onScroll}>
                  {!playerVisible ? (
                    <Container maxWidth="lg" className="jumbo-container">
                      <Jumbotron
                        type={i18n.t('MENTOR')}
                        name={biography.attributes?.name}
                        title={biography.attributes?.mentor_title}
                        author={biography.org}
                        partners={biography.partners}
                        short_description={biography.short_description}
                        id={biography.id}
                        user={user}
                        sponsors={biography.sponsors}
                        educationalResource={biography}
                        isMobile={isMobile}
                        handlePlayVideo={showVideoPlayer}
                        handleSelection={handleSelection}
                        history={props.history}
                      />
                    </Container>
                  ) : (
                    <div ref={playerContainerRef}>
                      <FullScreenPlayer
                        isMobile={isMobile}
                        course={biography}
                        showVideoPlayer={showVideoPlayer}
                        setSelectedTab={(tab, divId) => {
                          setSelectedTab(tab);
                          if (tab < 5) {
                            document.getElementById(divId).scrollIntoView();
                          }
                        }}
                      />
                    </div>
                  )}
                  <Container maxWidth="lg" className="mentor-body">
                    <Grid container>
                      <Grid item xs={12} md={5}>
                        <Chronology {...chronologyProps} />
                      </Grid>
                      <Grid item xs={12} md={7} className="mentor-text-section">
                        <TextSection text={firstSectionText} />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={12}>
                        <MentorBiographyBody biography={biographyContent} />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </>
            )}
          </div>

          <MoreCourses courses={relatedCourses} limit={2} />

          <EarlyBidModal
            open={openEarlyBidModal}
            handleClose={(e) => {
              e.preventDefault();
              setOpenEarlyBidModal(false);
              Cookies.set('early-access', 'false', {
                domain,
              });
            }}
          />

          <RequestInfo
            open={openRequestInfoModal}
            handleClose={(e) => {
              e.preventDefault();
              setOpenRequestInfoModal(false);
            }}
          />
          <Footer className="home" user={user} />
        </>
      )}
    </>
  );
};

Mentor.propTypes = {
  actions: PropTypes.shape({
    getBiographyById: PropTypes.func,
    getCoursesByMentor: PropTypes.func,
  }),
  biographies: biographyType,
  degree: degreeType,
  enrollments: educationalResourcesType,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  menu: menuType,
  search: educationalResourcesType,
  staticContext: PropTypes.object,
  user: userType,
  courses: educationalResourcesType,
};

Mentor.defaultProps = {
  actions: {
    getBiographyById: () => {},
    getCoursesByMentor: () => {},
  },
  biographies: {},
  degree: {},
  enrollments: [],
  menu: [],
  search: [],
  staticContext: {},
  user: {},
  courses: {},
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getBiographyById: bindActionCreators(getBiographyById, dispatch),
    getCoursesByMentor: bindActionCreators(getCoursesByMentor, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Mentor);
