/* eslint-disable react/no-danger */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */

import React, { useEffect, useRef, useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import ButtonMoreIcon from '../../../assets/images/button-arrow-more.svg';
import ButtonMoreClosedIcon from '../../../assets/images/button-arrow-less.svg';

import ButtonCircleMoreIcon from '../../../assets/images/button-more.svg';
import ButtonCircleMoreClosedIcon from '../../../assets/images/button-more-closed.svg';

import ButtonArrowUpIcon from '../../../assets/images/arrow-up.svg';

import PlayVideoIcon from '../../../assets/images/play-video-icon.svg';
import DiaryIcon from '../../../assets/images/diary-icon.svg';
import TextIcon from '../../../assets/images/text-icon.svg';

import i18n from '../../../languages';
import './index.scss';
import PriceContent from '../CourseAbout/PriceContent';
import CourseStartCountdown from '../CourseAbout/CourseStartCountdown';
import ShareModal from '../../Common/Modals/Share';

//import courseData from '../../../constants/courses/es/courses';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';

const FullCourseProgram = ({
  course,
  user,
  sectionClicked,
  tag,
  handleBackToResume,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [subExpanded, setSubExpanded] = React.useState(false);
  const [featureExpanded, setFeatureExpanded] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const sectionRef = useRef([]);
  const subsectionRef = useRef([]);

  const getKeyCourse = (cid) => {
    if (
      cid === 'the-end-of-professional-sports-career' ||
      cid === 'fin-de-la-carrera-run02-t3'
    ) {
      return 'fin-de-la-carrera-run02-t3';
    } else if (cid === 'sports-comentator' || cid === 'narracion-deportiva') {
      return 'narracion-deportiva';
    } else if (cid === 'sport-analyst' || cid === 'comentario-deportivo') {
      return 'comentario-deportivo';
    } else if (cid === 'train-your-brain' || cid === 'entrena-tu-cerebro') {
      return 'entrena-tu-cerebro';
    } else if (
      cid === 'professional-basketball-coaching' ||
      cid === 'direccion-tecnica-profesional-basketball'
    ) {
      return 'direccion-tecnica-profesional-basketball';
    } else if (
      cid === 'soccer-women-coaching' ||
      cid === 'direccion-tecnica-futbol-femenino'
    ) {
      return 'direccion-tecnica-futbol-femenino';
    }
  };

  console.log('course', course);

  const sections = course.structure.map((section) => section.id);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeExpanded = (panel) => (_, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  const handleChangeFeatureExpanded = (panel) => (_, isExpanded) => {
    setFeatureExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    document.querySelector('.action-bar').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    window.scrollBy(0, -250);
  }, []);

  useEffect(() => {
    if (sectionClicked) {
      setExpanded('panel3');
      sectionRef.current[1].click();
      if (tag) {
        console.log('tag', tag);
        subsectionRef.current[tag].click();
        document.querySelector(`#subsection-${tag}`).scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        document.querySelector('#panel3bh-content').scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [sectionClicked, tag]);

  return (
    <div className="full-course-program" id="#fullprogram">
      <div>
        <Container maxWidth="lg">
          <Grid container justifyContent="center">
            <Grid item sm={7} md={8} className="full-content-course">
              {course.structure.map((section, index) => {
                return (
                  <ExpansionPanel
                    key={`panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    expanded={expanded === `panel${index}`}
                  >
                    <ExpansionPanelSummary
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      ref={(el) => (sectionRef.current[index] = el)}
                      className="structure"
                    >
                      <Grid
                        container
                        justifyContent="flex-end"
                        className="structure-container"
                      >
                        <Grid item xs={11} sm={11}>
                          <h3>{section.title}</h3>
                          <h4>{section.name}</h4>
                          <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: section.description,
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} sm={1} className="more text-right">
                          <>
                            {expanded === `panel${index}` ? (
                              <img
                                src={ButtonCircleMoreClosedIcon}
                                alt="Ver menos"
                                className="btn-close-open"
                              />
                            ) : (
                              <img
                                src={ButtonCircleMoreIcon}
                                alt="Ver menos"
                                className="btn-close-open"
                              />
                            )}
                          </>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>

                    <AccordionDetails>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classnames(
                          expanded === `panel${index}` &&
                            subExpanded === false &&
                            'opened',
                        )}
                      >
                        <Grid container className="unit-container">
                          <Grid item xs={12} sm={12}>
                            {section.subsections ? (
                              section.subsections.map(
                                (subsection, subindex) => (
                                  <ExpansionPanel
                                    key={`panel${index}-${subindex}`}
                                    defaultExpanded={true}
                                    expanded={
                                      subExpanded ===
                                      `panel${index}-${subindex}`
                                    }
                                    onChange={handleChangeExpanded(
                                      `panel${index}-${subindex}`,
                                    )}
                                    className="subsection-panel"
                                  >
                                    <ExpansionPanelSummary
                                      aria-controls={`panel${index}-${subindex}bh-content`}
                                      id={`panel${index}-${subindex}bh-header`}
                                      ref={(el) =>
                                        (subsectionRef.current[
                                          subsection.id
                                        ] = el)
                                      }
                                    >
                                      <Grid
                                        container
                                        justifyContent="flex-end"
                                        className="subsection-container"
                                      >
                                        <a
                                          className="anchor"
                                          name={subsection.id}
                                          id={`subsection-${subsection.id}`}
                                          href={`#subsection-${subsection.id}`}
                                        />

                                        <Grid item xs={12} sm={11}>
                                          <h5
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                              __html: subsection.title,
                                            }}
                                          />
                                          <h3
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                              __html: subsection.name,
                                            }}
                                          />
                                          <div
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                              __html: subsection.description,
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={1}
                                          className="more text-right"
                                        >
                                          <>
                                            {subExpanded ===
                                            `panel${index}-${subindex}` ? (
                                              <img
                                                src={ButtonMoreClosedIcon}
                                                alt="Ver menos"
                                                className="btn-close-open"
                                              />
                                            ) : (
                                              <img
                                                src={ButtonMoreIcon}
                                                alt="Ver más"
                                                className="btn-close-open"
                                              />
                                            )}
                                          </>
                                        </Grid>
                                      </Grid>
                                    </ExpansionPanelSummary>
                                    <AccordionDetails
                                      className={classnames(
                                        'labeled-content',
                                        subExpanded ===
                                          `panel${index}-${subindex}` &&
                                          'opened',
                                      )}
                                    >
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <div className="units-title">
                                            <h3>
                                              {i18n.t('FEATURED_CONTENT')}
                                            </h3>
                                          </div>
                                        </Grid>
                                        {subsection.units.map((unit, sidx) => (
                                          <Grid item xs={12} key={sidx}>
                                            <ExpansionPanel
                                              key={`fpanel${index}-${subindex}-${sidx}`}
                                              expanded={
                                                featureExpanded ===
                                                `fpanel${index}-${subindex}-${sidx}`
                                              }
                                              onChange={handleChangeFeatureExpanded(
                                                `fpanel${index}-${subindex}-${sidx}`,
                                              )}
                                              defaultExpanded={true}
                                            >
                                              <Grid container>
                                                <Grid item xs={12} sm={11}>
                                                  <Grid
                                                    container
                                                    justifyContent="flex-end"
                                                    className="subsection-container"
                                                  >
                                                    <Grid item xs={1}>
                                                      <img
                                                        src={
                                                          unit.content_type ===
                                                          'video'
                                                            ? PlayVideoIcon
                                                            : unit.content_type ===
                                                              'text-icon'
                                                            ? TextIcon
                                                            : DiaryIcon
                                                        }
                                                        alt={
                                                          unit.content_type ===
                                                          'video-icon'
                                                            ? 'video'
                                                            : unit.content_type ===
                                                              'text-icon'
                                                            ? 'text'
                                                            : 'diary'
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12} sm={11}>
                                                      <h5
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                          __html: unit.title,
                                                        }}
                                                      />
                                                      <p
                                                        className="featured-content-name"
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                          __html: unit.name,
                                                        }}
                                                      />
                                                      <div
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            unit.description,
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </ExpansionPanel>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </AccordionDetails>
                                    <div
                                      style={{
                                        textAlign: 'right',
                                        padding: 10,
                                      }}
                                    >
                                      <img
                                        src={ButtonArrowUpIcon}
                                        alt="Ver menos"
                                        className="btn-close-open"
                                        onClick={() =>
                                          subsectionRef.current[
                                            subsection.id
                                          ].click()
                                        }
                                      />
                                    </div>
                                  </ExpansionPanel>
                                ),
                              )
                            ) : (
                              <Grid container direction="column">
                                <Grid item>
                                  <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: section.id,
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{ textAlign: 'right', padding: 10 }}
                                >
                                  <img
                                    src={ButtonArrowUpIcon}
                                    alt="Ver menos"
                                    className="btn-close-open"
                                    onClick={() =>
                                      sectionRef.current[index].click()
                                    }
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </ExpansionPanel>
                );
              })}
            </Grid>

            <Grid item xs={1} />
            <Grid item sm={4} md={3} style={{ width: '100%' }}>
              {user.logged &&
                course.bought &&
                course.runs_dates &&
                course.runs_dates[0] && (
                  <CourseStartCountdown start={course.runs_dates[0].start} />
                )}
              <PriceContent {...course} />

              <div
                className="btn-black share-button"
                onClick={(event) => {
                  event.preventDefault();
                  setOpenModal(true);
                }}
              >
                {i18n.t('SHARE_COURSE_PROGRAM')}
              </div>
              <ShareModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                idContent={course.id}
                name={course.name}
                description={course.short_description}
              />
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              style={
                isMobile
                  ? { width: '100%', marginTop: 30, marginBottom: 30 }
                  : { marginTop: 115 }
              }
            >
              <button
                onClick={handleBackToResume}
                className={'btn-secondary dark md'}
                style={isMobile ? { width: '100%' } : {}}
              >
                {i18n.t('BACK_TO_RESUME')}
              </button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default FullCourseProgram;
