/* eslint-disable react/no-danger */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { isMobile } from 'react-device-detect';

import ButtonMoreIcon from '../../../assets/images/button-more.svg';
import ButtonMoreClosedIcon from '../../../assets/images/button-more-closed.svg';
import ButtonGoIcon from '../../../assets/images/arrow-right-black-circle.svg';

import { userType } from '../../../types/userType';
import { structuresType } from '../../../types/structureType';

import i18n from '../../../languages';

import './index.scss';

import courseData from '../../../constants/courses/es/courses';

const echoImage = (image) => {
  try {
    return require(`../../../assets/images/icons/unit-icons/${image}.svg`)
      .default;
  } catch (e) {
    return false;
  }
};

//Get cms courser keys - Sergio Hernández
const getKeyCourse = (cid) => {
  if (
    cid === 'the-end-of-professional-sports-career' ||
    cid === 'fin-de-la-carrera-run02-t3'
  ) {
    return 'fin-de-la-carrera-run02-t3';
  } else if (cid === 'sports-comentator' || cid === 'narracion-deportiva') {
    return 'narracion-deportiva';
  } else if (cid === 'sport-analyst' || cid === 'comentario-deportivo') {
    return 'comentario-deportivo';
  } else if (cid === 'train-your-brain' || cid === 'entrena-tu-cerebro') {
    return 'entrena-tu-cerebro';
  } else if (
    cid === 'professional-basketball-coaching' ||
    cid === 'direccion-tecnica-profesional-basketball'
  ) {
    return 'direccion-tecnica-profesional-basketball';
  } else if (
    cid === 'soccer-women-coaching' ||
    cid === 'direccion-tecnica-futbol-femenino'
  ) {
    return 'direccion-tecnica-futbol-femenino';
  }
};

const CourseProgram = (props) => {
  const { structure, id } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [subExpanded, setSubExpanded] = React.useState(false);
  const regex = /(<([^>]+)>)/gi;

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeExpanded = (panel) => (_, isExpanded) => {
    setSubExpanded(isExpanded ? panel : false);
  };

  const courseJson = courseData[getKeyCourse(props.id)];

  return (
    <div className="course-program">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={12} className="content">
            <Grid container className="title">
              <Grid item xs={6} sm={9} className="content">
                <h2
                  dangerouslySetInnerHTML={{ __html: i18n.t('COURSE_CONTENT') }}
                />
              </Grid>
              <Grid item xs={6} sm={3} style={{ textAlign: 'right' }}>
                <button
                  onClick={props.handleFullProgram}
                  className={
                    isMobile ? 'btn-white' : 'btn-secondary dark md arrow-right'
                  }
                  style={
                    isMobile
                      ? {
                          textDecoration: 'underline',

                          textAlign: 'right',
                        }
                      : { width: '100%', paddingTop: 0 }
                  }
                >
                  {isMobile
                    ? i18n.t('VIEW_PROGRAM')
                    : i18n.t('WATCH_ALL_CONTENT')}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Container maxWidth="lg" style={isMobile ? { paddingLeft: 10 } : {}}>
          <Grid container>
            <Grid item sm={12} xs={12} className="content-program">
              {structure.map((section, index) => {
                const expand = !isMobile && {
                  expanded: expanded === `panel${index}`,
                };
                return (
                  <ExpansionPanel
                    key={`panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    {...expand}
                  >
                    {isMobile ? (
                      <ExpansionPanelSummary
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                        className="structure"
                      >
                        <Grid
                          container
                          justifyContent="flex-end"
                          className="structure-container"
                        >
                          <Grid item xs={10} sm={4} className="title">
                            <h3>{section.title.replace(regex, '')}</h3>
                          </Grid>

                          <Grid item xs={2} sm={1} className="more text-right">
                            <>
                              {expanded === `panel${index}` ? (
                                <img
                                  src={ButtonMoreClosedIcon}
                                  alt="Ver menos"
                                  className="btn-close-open"
                                />
                              ) : (
                                <img
                                  src={ButtonMoreIcon}
                                  alt="Ver más"
                                  className="btn-close-open"
                                />
                              )}
                            </>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>
                    ) : (
                      <>
                        <Grid
                          container
                          justifyContent="flex-end"
                          className="structure-container-full"
                        >
                          <Grid item xs={12} sm={12} className="date">
                            {i18n.t('PHASE')} {index + 1}
                          </Grid>
                          <Grid item xs={12} sm={4} className="title">
                            <h3>{section.title.replace(regex, '')}</h3>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            className="content-main"
                            style={{ paddingLeft: 15 }}
                          >
                            <p>
                              {section.description
                                .replace(regex, '')
                                .slice(0, 260) + '...'}
                            </p>
                          </Grid>
                          <Grid item xs={12} sm={1} className="more text-right">
                            <img
                              src={ButtonGoIcon}
                              alt="Ver más"
                              className="btn-close-open"
                              onClick={() =>
                                props.handleClickSection(section.id)
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <AccordionDetails
                      className={classnames(
                        expanded === `panel${index}` &&
                          subExpanded === false &&
                          'opened',
                      )}
                    >
                      <Grid item sm={12}>
                        <Grid container style={{ paddingLeft: 20 }}>
                          <p>
                            {section.description
                              .replace(regex, '')
                              .slice(0, 260) + '...'}
                          </p>
                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <button
                              className="btn-white"
                              style={{
                                textDecoration: 'underline',
                                padding: 5,
                                textAlign: 'center',
                              }}
                              onClick={props.handleFullProgram}
                            >
                              {i18n.t('VIEW_PROGRAM')}
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </ExpansionPanel>
                );
              })}
            </Grid>
            {!isMobile && (
              <div className="show-full-program">
                <Grid container>
                  <Grid item sm={9}></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    style={isMobile ? { padding: 10 } : {}}
                  >
                    <button
                      onClick={props.handleFullProgram}
                      style={{ width: '100%' }}
                      className="btn-full-program btn-primary md arrow-right "
                    >
                      {i18n.t('WATCH_ALL_CONTENT')}
                    </button>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

CourseProgram.propTypes = {
  structure: structuresType,
  user: userType,
  other_settings: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
};

CourseProgram.defaultProps = {
  structure: [],
  user: {},
  other_settings: '',
  id: '',
  status: '',
};

export default CourseProgram;
