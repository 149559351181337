const NAME_SPACE = 'oxygen';
const NS_USER = 'user';
const NS_COURSE = 'course';
const NS_BANNER = 'banner';
const NS_BIOGRAPHY = 'biography';
const NS_PARTNER = 'partner';
const NS_PAGE = 'page';
const NS_ENROLLMENT = 'enrollment';
const NS_SEARCH = 'search';
const NS_MENU = 'search';
const NS_EDUCATIONALRESOURCE = 'educationalResource';
const NS_DEGREE = 'degree';
const NS_MARKETING_EMAIL = 'marketingEmail';

export const GET_USER = `${NAME_SPACE}/${NS_USER}/GET_USER`;

export const GET_EDUCATIONALRESOURCES_REQUEST = `${NAME_SPACE}/${NS_EDUCATIONALRESOURCE}/GET_EDUCATIONALRESOURCES_REQUEST`;
export const GET_EDUCATIONALRESOURCES_SUCCESS = `${NAME_SPACE}/${NS_EDUCATIONALRESOURCE}/GET_EDUCATIONALRESOURCES_SUCCESS`;
export const GET_EDUCATIONALRESOURCES_ERROR = `${NAME_SPACE}/${NS_EDUCATIONALRESOURCE}/GET_EDUCATIONALRESOURCES_ERROR`;

export const GET_COURSE_REQUEST = `${NAME_SPACE}/${NS_COURSE}/GET_COURSE_REQUEST`;
export const GET_COURSE_SUCCESS = `${NAME_SPACE}/${NS_COURSE}/GET_COURSE_SUCCESS`;
export const GET_COURSE_ERROR = `${NAME_SPACE}/${NS_COURSE}/GET_COURSE_ERROR`;

export const GET_COURSES_REQUEST = `${NAME_SPACE}/${NS_COURSE}/GET_COURSES_REQUEST`;
export const GET_COURSES_SUCCESS = `${NAME_SPACE}/${NS_COURSE}/GET_COURSES_SUCCESS`;
export const GET_COURSES_ERROR = `${NAME_SPACE}/${NS_COURSE}/GET_COURSES_ERROR`;

export const GET_COURSES_BY_MENTOR_REQUEST = `${NAME_SPACE}/${NS_COURSE}/GET_COURSES_BY_MENTOR_REQUEST`;
export const GET_COURSES_BY_MENTOR_SUCCESS = `${NAME_SPACE}/${NS_COURSE}/GET_COURSES_BY_MENTOR_SUCCESS`;
export const GET_COURSES_BY_MENTOR_ERROR = `${NAME_SPACE}/${NS_COURSE}/GET_COURSES_BY_MENTOR_ERROR`;

export const GET_BANNERS_REQUEST = `${NAME_SPACE}/${NS_BANNER}/GET_BANNERS_REQUEST`;
export const GET_BANNERS_SUCCESS = `${NAME_SPACE}/${NS_BANNER}/GET_BANNERS_SUCCESS`;
export const GET_BANNERS_ERROR = `${NAME_SPACE}/${NS_BANNER}/GET_BANNERS_ERROR`;

export const GET_BANNER_BY_ID_REQUEST = `${NAME_SPACE}/${NS_BANNER}/GET_BANNER_BY_ID_REQUEST`;
export const GET_BANNER_BY_ID_SUCCESS = `${NAME_SPACE}/${NS_BANNER}/GET_BANNER_BY_ID_SUCCESS`;
export const GET_BANNER_BY_ID_ERROR = `${NAME_SPACE}/${NS_BANNER}/GET_BANNER_BY_ID_ERROR`;

export const GET_BIOGRAPHIES_REQUEST = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_BIOGRAPHIES_REQUEST`;
export const GET_BIOGRAPHIES_SUCCESS = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_BIOGRAPHIES_SUCCESS`;
export const GET_BIOGRAPHIES_ERROR = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_BIOGRAPHIES_ERROR`;

export const GET_BIOGRAPHY_BY_ID_REQUEST = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_BIOGRAPHY_BY_ID_REQUEST`;
export const GET_BIOGRAPHY_BY_ID_SUCCESS = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_BIOGRAPHY_BY_ID_SUCCESS`;
export const GET_BIOGRAPHY_BY_ID_ERROR = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_BIOGRAPHY_BY_ID_ERROR`;

export const GET_DEGREE_REQUEST = `${NAME_SPACE}/${NS_DEGREE}/GET_DEGREE_REQUEST`;
export const GET_DEGREE_SUCCESS = `${NAME_SPACE}/${NS_DEGREE}/GET_DEGREE_SUCCESS`;
export const GET_DEGREE_ERROR = `${NAME_SPACE}/${NS_DEGREE}/GET_DEGREE_ERROR`;

export const GET_MENU_REQUEST = `${NAME_SPACE}/${NS_MENU}/GET_MENU_REQUEST`;
export const GET_MENU_SUCCESS = `${NAME_SPACE}/${NS_MENU}/GET_MENU_SUCCESS`;
export const GET_MENU_ERROR = `${NAME_SPACE}/${NS_MENU}/GET_MENU_ERROR`;

export const GET_SEARCH_REQUEST = `${NAME_SPACE}/${NS_SEARCH}/GET_SEARCH_REQUEST`;
export const GET_SEARCH_SUCCESS = `${NAME_SPACE}/${NS_SEARCH}/GET_SEARCH_SUCCESS`;
export const GET_SEARCH_ERROR = `${NAME_SPACE}/${NS_SEARCH}/GET_SEARCH_ERROR`;

export const GET_ENROLLMENTS_REQUEST = `${NAME_SPACE}/${NS_ENROLLMENT}/GET_ENROLLMENTS_REQUEST`;
export const GET_ENROLLMENTS_SUCCESS = `${NAME_SPACE}/${NS_ENROLLMENT}/GET_ENROLLMENTS_SUCCESS`;
export const GET_ENROLLMENTS_ERROR = `${NAME_SPACE}/${NS_ENROLLMENT}/GET_ENROLLMENTS_ERROR`;

export const SEND_MARKETING_EMAIL_REQUEST = `${NAME_SPACE}/${NS_MARKETING_EMAIL}/SEND_MARKETING_EMAIL_REQUEST`;
export const SEND_MARKETING_EMAIL_SUCCESS = `${NAME_SPACE}/${NS_MARKETING_EMAIL}/SEND_MARKETING_EMAIL_UCCESS`;
export const SEND_MARKETING_EMAIL_ERROR = `${NAME_SPACE}/${NS_MARKETING_EMAIL}/SEND_MARKETING_EMAIL_ERROR`;

export const GET_PARTNERS_REQUEST = `${NAME_SPACE}/${NS_PARTNER}/GET_PARTNERS_REQUEST`;
export const GET_PARTNERS_SUCCESS = `${NAME_SPACE}/${NS_PARTNER}/GET_PARTNERS_SUCCESS`;
export const GET_PARTNERS_ERROR = `${NAME_SPACE}/${NS_PARTNER}/GET_PARTNERS_ERROR`;

export const GET_PAGE_BY_SLUG_REQUEST = `${NAME_SPACE}/${NS_PAGE}/GET_PAGE_BY_SLUG_REQUEST`;
export const GET_PAGE_BY_SLUG_SUCCESS = `${NAME_SPACE}/${NS_PAGE}/GET_PAGE_BY_SLUG_SUCCESS`;
export const GET_PAGE_BY_SLUG_ERROR = `${NAME_SPACE}/${NS_PAGE}/GET_PAGE_BY_SLUG_ERROR`;

export const GET_FAQS_REQUEST = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_FAQS_REQUEST`;
export const GET_FAQS_SUCCESS = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_FAQS_SUCCESS`;
export const GET_FAQS_ERROR = `${NAME_SPACE}/${NS_BIOGRAPHY}/GET_FAQS_ERROR`;

export default {
  GET_USER,
  GET_EDUCATIONALRESOURCES_REQUEST,
  GET_EDUCATIONALRESOURCES_SUCCESS,
  GET_EDUCATIONALRESOURCES_ERROR,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_BY_MENTOR_REQUEST,
  GET_COURSES_BY_MENTOR_SUCCESS,
  GET_COURSES_BY_MENTOR_ERROR,
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_ERROR,
  GET_BANNER_BY_ID_REQUEST,
  GET_BANNER_BY_ID_SUCCESS,
  GET_BANNER_BY_ID_ERROR,
  GET_BIOGRAPHIES_REQUEST,
  GET_BIOGRAPHIES_SUCCESS,
  GET_BIOGRAPHIES_ERROR,
  GET_BIOGRAPHY_BY_ID_REQUEST,
  GET_BIOGRAPHY_BY_ID_SUCCESS,
  GET_BIOGRAPHY_BY_ID_ERROR,
  GET_DEGREE_REQUEST,
  GET_DEGREE_SUCCESS,
  GET_DEGREE_ERROR,
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  GET_SEARCH_REQUEST,
  GET_SEARCH_SUCCESS,
  GET_SEARCH_ERROR,
  GET_ENROLLMENTS_REQUEST,
  GET_ENROLLMENTS_SUCCESS,
  GET_ENROLLMENTS_ERROR,
  SEND_MARKETING_EMAIL_REQUEST,
  SEND_MARKETING_EMAIL_SUCCESS,
  SEND_MARKETING_EMAIL_ERROR,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_SUCCESS,
  GET_PARTNERS_ERROR,
  GET_PAGE_BY_SLUG_REQUEST,
  GET_PAGE_BY_SLUG_SUCCESS,
  GET_PAGE_BY_SLUG_ERROR,
  GET_FAQS_REQUEST,
  GET_FAQS_SUCCESS,
  GET_FAQS_ERROR,
};
