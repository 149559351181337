import i18n from '../../../languages';
import React, { useState } from 'react';
import './index.scss';

const PlayButton = ({ handleClick, isMobile, big, hasText }) => {
  const [hover, setHover] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  };

  return (
    <div className="play-button">
      <a
        onClick={handleButtonClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {isMobile ? (
          <svg
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0102 21.7414C16.5912 21.7414 21.1154 17.2243 21.1154 11.6522C21.1154 6.08008 16.5912 1.56299 11.0102 1.56299C5.42919 1.56299 0.904907 6.08008 0.904907 11.6522C0.904907 17.2243 5.42919 21.7414 11.0102 21.7414Z"
              stroke="#52E0FF"
              strokeWidth="1.34737"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.98877 7.6167L15.0519 11.6524L8.98877 15.6881V7.6167Z"
              stroke="#52E0FF"
              strokeWidth="1.34737"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : hover ? (
          big ? (
            <svg
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="48" cy="48" r="48" fill="black" fillOpacity="0.6" />
              <path
                d="M48.5 86C69.2107 86 86 69.2107 86 48.5C86 27.7893 69.2107 11 48.5 11C27.7893 11 11 27.7893 11 48.5C11 69.2107 27.7893 86 48.5 86Z"
                stroke="#52E0FF"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M41.001 33.5L63.501 48.5L41.001 63.5V33.5Z"
                stroke="#52E0FF"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 38C29.7173 38 38 29.7173 38 19.5C38 9.28273 29.7173 1 19.5 1C9.28273 1 1 9.28273 1 19.5C1 29.7173 9.28273 38 19.5 38Z"
                stroke="#52e0ff"
                strokeOpacity="0.7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.7998 12.1L26.8998 19.5L15.7998 26.9V12.1Z"
                stroke="#52e0ff"
                strokeOpacity="0.7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )
        ) : big ? (
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34 67C52.2254 67 67 52.2254 67 34C67 15.7746 52.2254 1 34 1C15.7746 1 1 15.7746 1 34C1 52.2254 15.7746 67 34 67Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M27.4004 20.7998L47.2004 33.9998L27.4004 47.1998V20.7998Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 38C29.7173 38 38 29.7173 38 19.5C38 9.28273 29.7173 1 19.5 1C9.28273 1 1 9.28273 1 19.5C1 29.7173 9.28273 38 19.5 38Z"
              stroke="white"
              strokeOpacity="0.7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.7998 12.1L26.8998 19.5L15.7998 26.9V12.1Z"
              stroke="white"
              strokeOpacity="0.7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </a>
      {hasText && (
        <span className="play-button-text">{i18n.t('WATCH_TRAILER')}</span>
      )}
    </div>
  );
};

export default PlayButton;
