/* eslint-disable global-require */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import lodash from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import i18n from '../../../../languages';

import { pricingsType } from '../../../../types/pricingType';
import { structureType } from '../../../../types/structureType';
import languageType from '../../../../types/languageType';

import DesktopIcon from '../../../../assets/images/desktop-light.svg';
import StopWatchIcon from '../../../../assets/images/stopwatch-light.svg';
import TeacherIcon from '../../../../assets/images/chalkboard-teacher-light.svg';
import LanguageIcon from '../../../../assets/images/language-icon.svg';
import CourseOffThePichIcon from '../../../../assets/images/off-thepitch-gray.svg';
import CourseOnThePichIcon from '../../../../assets/images/on-thepitch-gray.svg';
import RequirementsIcon from '../../../../assets/images/requirements.svg';
import StartDateIcon from '../../../../assets/images/start-date.svg';
import CalendarIcon from '../../../../assets/images/calendar.svg';
import Thumbsup from '../../../../assets/images/thumbs-up.svg';

import './index.scss';

const dateFormat = i18n.culture === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

const echoImage = (image) => {
  try {
    return require(`../../../../assets/images/icons/unit-icons/${image}.svg`)
      .default;
  } catch (e) {
    return false;
  }
};

const PriceContent = ({
  available_language,
  bought,
  end,
  effort,
  effort_description,
  oxygen_subtype: oxygenSubtype,
  pricing,
  requirements,
  structure,
  teaching_team,
  status,
  runs_dates,
}) => {
  let units = [];

  structure &&
    Array.isArray(structure) &&
    structure.forEach((structureChild) => {
      structureChild.subsections &&
        structureChild.subsections.forEach((subsection) => {
          subsection.units &&
            subsection.units.forEach((unit) => {
              units.push(unit.content_type);
            });
        });
    });

  units = [...new Set(units)];

  const price = pricing && pricing[0] && pricing[0].price;
  const priceCurrency = pricing && pricing[0] && pricing[0].currency;
  const currenciesList =
    pricing &&
    pricing.length > 0 &&
    pricing
      .map((price) => price.currency && ` ${price.currency}`)
      .join()
      .toUpperCase();

  return (
    <>
      <div className="price-content">
        <Grid item xs={12} className="content">
          <Grid container className="course-features">
            <Grid item xs={2} className="icon">
              <img src={Thumbsup} alt="100% Online" />
            </Grid>
            <Grid item xs={10}>
              <h4>{i18n.t('THUMBS_UP')}</h4>
            </Grid>
          </Grid>
          <hr />

          <Grid container className="course-features">
            <Grid item xs={2} className="icon">
              <img src={DesktopIcon} alt="100% Online" />
            </Grid>
            <Grid item xs={10}>
              <h4>{i18n.t('TIME_100_ONLINE')}</h4>
              <p>{i18n.t('START_NOW_AND_LEARN')}</p>
            </Grid>

            {(effort || effort_description) && (
              <>
                <Grid item xs={2} className="icon">
                  <img src={StopWatchIcon} alt="Time" />
                </Grid>
                <Grid item xs={10}>
                  {effort && (
                    <h4>
                      {effort} {i18n.t('TO_COMPLETE')}
                    </h4>
                  )}
                  {effort_description && <p>{effort_description}</p>}
                </Grid>
              </>
            )}

            {teaching_team && (
              <>
                <Grid item xs={2} className="icon">
                  <img src={TeacherIcon} alt="Teacher" />
                </Grid>
                <Grid item xs={10}>
                  <h4>{i18n.t('TEACHING_TEAM')}</h4>
                  <p>{teaching_team}</p>
                </Grid>
              </>
            )}

            {available_language && (
              <>
                <Grid item xs={2} className="icon">
                  <img src={LanguageIcon} alt="Language" />
                </Grid>
                <Grid item xs={10}>
                  <h4>{i18n.t('LANGUAGE')}</h4>
                  <p>{available_language.name}</p>
                </Grid>
              </>
            )}

            {requirements && (
              <>
                <Grid item xs={2} className="icon">
                  <img src={RequirementsIcon} alt="Requirements" />
                </Grid>
                <Grid item xs={10}>
                  <h4>{i18n.t('REQUIREMENTS')}</h4>
                  <p>{requirements}</p>
                </Grid>
              </>
            )}

            {oxygenSubtype === 'on_the_pitch' ? (
              <>
                <Grid item xs={2} className="icon">
                  <img src={CourseOnThePichIcon} alt="On The Pitch" />
                </Grid>
                <Grid item xs={10}>
                  <h4>ON The Pitch</h4>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={2} className="icon">
                  <img src={CourseOffThePichIcon} alt="Off The Pitch" />
                </Grid>
                <Grid item xs={10}>
                  <h4>OFF The Pitch</h4>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <small>{i18n.t('STARTING_DATES_DISCLAIMER')}</small>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {status !== 'email_capture_no_date' &&
        runs_dates &&
        runs_dates.length > 0 && (
          <div className="price-content">
            <Grid item xs={12} className="content">
              <Grid container className="available-dates">
                <Grid item xs={12}>
                  <h2>
                    <img src={CalendarIcon} alt="Available" />
                    {i18n.t('STARTING_DATES')}
                  </h2>
                  <hr />
                  <ul>
                    {runs_dates.map((date) => (
                      <li key={lodash.uniqueId('run_unit_')}>
                        {moment(date.start).format('D MMM YYYY').toUpperCase()}
                        {' - '}
                        {moment(date.end).format('D MMM YYYY').toUpperCase()}
                      </li>
                    ))}
                  </ul>
                  <small>{i18n.t('AVAILABLE_DAYS_RULES')}</small>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}

      {status === 'buyable' && !bought && price && price > 0 ? (
        <div className="price-content">
          <Grid item xs={12} className="content">
            <Grid container className="course-price">
              <Grid item xs={12}>
                <small className="currencies-disclaimer">
                  {i18n.t('TAXES_NOT_INCLUDED')}
                </small>
                <h2>
                  {priceCurrency} {price}
                </h2>
                <small>
                  {i18n.t('AVAILABLE_CURRENCIES')} {currenciesList}
                </small>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

PriceContent.propTypes = {
  available_language: languageType,
  effort: PropTypes.string,
  effort_description: PropTypes.string,
  oxygen_subtype: PropTypes.string,
  structure: structureType,
  pricing: pricingsType,
  requirements: PropTypes.string,
  teaching_team: PropTypes.string,
};

PriceContent.defaultProps = {
  available_language: '',
  effort: '',
  effort_description: '',
  oxygen_subtype: '',
  structure: {},
  pricing: {},
  requirements: '',
  teaching_team: '',
};

export default PriceContent;
