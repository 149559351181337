import api from '../../api';
import {
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_BY_MENTOR_REQUEST,
  GET_COURSES_BY_MENTOR_SUCCESS,
  GET_COURSES_BY_MENTOR_ERROR,
} from '../constants';

export const getCourse = (id) => (dispatch) => {
  dispatch({
    type: GET_COURSE_REQUEST,
    meta: api.courses
      .getCourse(id)
      .then((response) =>
        dispatch({
          type: GET_COURSE_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_COURSE_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export const getCourses = (id) => (dispatch) => {
  dispatch({
    type: GET_COURSES_REQUEST,
    meta: api.courses
      .getCourses(id)
      .then((response) =>
        dispatch({
          type: GET_COURSES_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_COURSES_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export const getCoursesByMentor = (slug) => (dispatch) => {
  dispatch({
    type: GET_COURSES_BY_MENTOR_REQUEST,
    meta: api.courses
      .getCoursesByMentor(slug)
      .then((response) =>
        dispatch({
          type: GET_COURSES_BY_MENTOR_SUCCESS,
          payload: {
            data: response.data || response,
          },
        }),
      )
      .catch((error) =>
        dispatch({
          type: GET_COURSES_BY_MENTOR_ERROR,
          payload: error.response,
        }),
      ),
  });
};

export default { getCourse, getCourses, getCoursesByMentor };
