import {
  GET_PAGE_BY_SLUG_REQUEST,
  GET_PAGE_BY_SLUG_SUCCESS,
  GET_PAGE_BY_SLUG_ERROR,
} from '../constants';

const initialState = {
  error: null,
  loading: true,
  page: {},
  pages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGE_BY_SLUG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PAGE_BY_SLUG_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        page: data,
        loading: false,
      };
    }
    case GET_PAGE_BY_SLUG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
