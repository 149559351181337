/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../../languages';

import Header from '../../../../components/Common/Header';
import Footer from '../../../../components/Common/Footer';
import MetaTags from '../../../../components/Common/MetaTags';

import '../../index.scss';

const PrivacyEN = () => {
  const { pathname } = useLocation();

  const langs = [{ en: '/info/privacy' }, { es: '/es/info/privacy' }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <MetaTags
        title={i18n.t('PAGE_PRIVA_TITLE')}
        description={i18n.t('PAGE_PRIVA_DESCRIPTION')}
        other_langs={langs}
      />
      <div className="page-wrap">
        <Header theme="black" />
        <div className="page privacy">
          <Container maxWidth="lg" className="container">
            <Grid item xs={12}>
              <h2>PRIVACY POLICY</h2>
              <p>
                <p>
                  <em>Date of preparation of this Privacy Policy 15/11/2021</em>
                </p>
                <p>
                  In accordance with the General Data Protection Regulation (EU)
                  2016/679 of the European Parliament and of the Council of 27
                  April 2016 (hereinafter, the "GDPR"), the purpose of this
                  Privacy Policy is to inform all those users subject to it of
                  the possible processing of their personal data.
                </p>
                <p>
                  When users visit the website{' '}
                  <a href="https://oxygen.education/">oxygen.education</a>, when
                  they request information, request or contract any of our
                  services, <strong>PLATFORM MEDIA</strong> may process their
                  personal data.
                </p>
                <p>
                  This Privacy Policy is intended to help you understand what
                  data <strong>PLATFORM MEDIA</strong> collects and what it does
                  with it. In this regard, this Privacy Policy explains, among
                  other things:
                </p>
                <ul>
                  <li>
                    what information <strong>PLATFORM MEDIA</strong> collects
                    and for what purposes;
                  </li>
                  <li>
                    what uses <strong>PLATFORM MEDIA</strong>makes of this
                    information;
                  </li>
                  <li>
                    the choices <strong>PLATFORM MEDIA</strong> offers in
                    relation to the information collected.
                  </li>
                </ul>
                <p></p>
                <p>
                  <strong>1. IDENTIFICATION OF THE CONTROLLER</strong>
                </p>
                <p>
                  <strong>PLATFORM MEDIA LAB, SA</strong>, inits capacity as
                  data controller, takes the privacy of users very seriously and
                  undertakes to do its utmost to respect it.
                </p>
                <p>
                  <strong>PLATFORM MEDIA</strong>&rsquo;s identification data
                  are:
                </p>
                <p>
                  Identity:{' '}
                  <strong>PLATFORM MEDIA LAB, SA - CHE: 426.769.305</strong>
                  <br />
                  Postal address:{' '}
                  <strong>
                    Route de Crassier 7, 1262 Eysins, Switzerland.
                  </strong>
                  <br />
                  Telephone: <strong>+41225951926</strong> - E-mail:
                  <strong>
                    {' '}
                    <a href="mailto:info@oxygen.education">
                      info@oxygen.education
                    </a>
                  </strong>
                </p>
                <p>
                  <strong>2. PERSONAL DATA WE PROCESS</strong>
                </p>
                <p>
                  <strong>PLATFORM MEDIA</strong> collects personal data in the
                  following ways:
                </p>
                <ul>
                  <li>
                    <strong>
                      Personal data that the user provides us with:
                    </strong>{' '}
                    when you fill in any of the forms at your disposal on the
                    website or any physical form either for registration or for
                    requesting information, or when you register and/or access
                    the platform or when you contract any training course or
                    service offered by <strong>PLATFORM MEDIA.</strong>
                  </li>
                  <li>
                    <strong>Through Cookies and similar technologies:</strong>{' '}
                    when you visit the <strong>PLATFORM MEDIA</strong> website,
                    it uses various technologies to collect and store
                    information and this may include the use of cookies or
                    similar technologies to identify your browser or device.{' '}
                    <strong>PLATFORM MEDIA</strong> also uses these technologies
                    to collect and store information when you interact with
                    services offered by <strong>PLATFORM MEDIA</strong>, or
                    other <em>Google </em>features that may appear on other
                    sites.
                    <br />
                  </li>

                  <li>
                    <strong>
                      Information we collect from social media and other content
                      platforms:{' '}
                    </strong>
                    If you access the Services through a third-party connection
                    or log-in (e.g., through a social network like Facebook or
                    Twitter), you may allow us to have access to and store
                    certain information from your social network profile. This
                    can include your name, gender, profile picture, your “likes”
                    and check-ins, and your list of friends, depending on your
                    settings on such services. If you do not wish to have this
                    information shared, do not use a social networking
                    connection to access the Services. For a description of how
                    social networking sites handle your information, please
                    refer to their privacy policies and terms of use, which may
                    permit you to modify your privacy settings. You may also
                    have the option of posting your Services activities to
                    Social Networking Services when you access content through
                    the Services (for example, you may post to Facebook that you
                    enrolled in a class on the Service); you acknowledge that if
                    you choose to use this feature, your friends, followers and
                    subscribers on any Social Networking Services you have
                    enabled will be able to view such activity.
                  </li>

                  <li>
                    You can obtain more information on how{' '}
                    <strong>PLATFORM MEDIA </strong>treats personal data
                    obtained through cookies in the "Cookies Policy".
                  </li>
                </ul>
                <p>
                  This personal data can be broadly grouped into the following
                  categories:
                </p>
                <ul>
                  <li>
                    <strong>Identification data: </strong>name; surname; ID card
                    number; telephone number; E-mail address; date of birth
                    (optional); gender.
                  </li>
                  <li>
                    <strong>Bank details: </strong>for direct debit payments.
                    Payments are processed by stripe, so the following data is
                    collected: first name; surname; E-mail; ID or passport
                    number; country of issue of the ID card; expiry date of the
                    ID card; full address.
                  </li>
                  <li>
                    <strong>Data for profiling: </strong>interests, hobbies.
                  </li>
                </ul>
                <p>
                  <strong>3. PURPOSES OF PROCESSING</strong>
                </p>
                <p>
                  The data collected in the different forms on this Website will
                  be used, in general, for the following purposes, which are
                  detailed below in relation to the same:
                </p>
                <ul>
                  <li>
                    To deal with requests for information and/or queries made by
                    the user, through the contact forms on the Website made
                    available to them.
                  </li>
                  <li>
                    Formalisation, execution and development of the online
                    training courses offered by <strong>PLATFORM MEDIA </strong>
                    and for which the user has previously registered.
                  </li>
                  <li>
                    For commercial and/or advertising actionsoncourses or
                    services that may be of interest to you. This acceptance
                    will always be revocable (associated with the exercise of
                    objection or limitation of processing purposes).
                  </li>
                </ul>
                <p>
                  <strong>4. CONFIDENTIALITY</strong>
                </p>
                <p>
                  The party responsible for the Website,{' '}
                  <strong>PLATFORM MEDIA,</strong> guarantees the
                  confidentiality and security of the data collected when they
                  are processed, to the extent that it has implemented the
                  processing policies and security measures (technical and
                  organisational) referred to in article 32 of Regulation (EU)
                  2016/679. The processing policies and security measures
                  implemented in the IT environment of the controller are
                  intended to prevent the alteration, loss, processing, or
                  unauthorised use of your personal data.
                </p>
                <p>
                  The purpose of use of the data referring to user queries is
                  guaranteed, both by the data controller and by those entities
                  that intervene in accordance with the purpose and object of
                  the processing.
                </p>
                <p>
                  <strong>5. EXERCISE OF DIGITAL RIGHTS</strong>
                </p>
                <p>
                  The user may, at any time, exercise his/her rights (E.g. the
                  right to request the deletion of his/her data) recognised in
                  the General Data Protection Regulation (Regulation (EU)
                  2016/679), by notifying <strong>PLATFORM MEDIA</strong> in the
                  terms established in the applicable regulations by writing to{' '}
                  <strong>PLATFORM MEDIA LAB, SA</strong>, enclosing a photocopy
                  of his/her National Identity Card, to the following address:
                  Route de Crassier 7, 1262 Eysins, Switzerland. You may also
                  communicate by e-mail to{' '}
                  <a href="mailto:dataprotection@oxygen.education">
                    dataprotection@oxygen.education
                  </a>
                </p>

                <p>
                  In order to facilitate the exercise of the DIGITAL RIGHTS that
                  the Law confers on you as a data subject,{' '}
                  <strong>PLATFORM MEDIA</strong> provides you with a form for
                  this purpose. You can request it by sending an email to{' '}
                  <a href="mailto:dataprotection@oxygen.education">
                    dataprotection@oxygen.education
                  </a>
                </p>

                <p>
                  Your local laws (e.g., in the EU) may permit you to request
                  that we:
                </p>
                <ul>
                  <li>delete certain data which we are holding about you</li>
                  <li>
                    restrict the way that we process and disclose certain of
                    your data
                  </li>
                  <li>
                    transfer your information to a third party provider of
                    services
                  </li>
                  <li>revoke your consent for the processing of your data</li>
                  <li>
                    provide access to and/or a copy of certain data we hold
                    about you
                  </li>
                  <li>
                    prevent the processing of your data for direct-marketing
                    purposes (including any direct marketing processing based on
                    profiling)
                  </li>
                  <li>update data which is out of date or incorrect</li>
                </ul>

                <p>
                  <strong>6. DELIMITATION OF RESPONSIBILITY</strong>
                </p>
                <p>
                  <strong>PLATFORM MEDIA </strong>assumes responsibility for the
                  processing of the data that may be carried out from the
                  present domain{' '}
                  <a href="https://oxygen.education/">oxygen.education</a>.
                </p>
                <p>
                  Therefore, <strong>PLATFORM MEDIA</strong> will be
                  individually and independently liable for the processing and
                  use of the data, as well as for the management of the requests
                  for Digital Rights that may be submitted to each of them by
                  the users that have been directly related to the present
                  Website (
                  <a href="https://oxygen.education/">oxygen.education</a>),{' '}
                  <strong>PLATFORM MEDIA </strong>does not assume any obligation
                  in relation to the processing of data that may be done outside
                  of its own domain and technological scope.
                </p>
                <p>
                  <strong>7. DATA QUALITY</strong>
                </p>
                <p>
                  <strong>PLATFORM MEDIA,</strong> as data controller, warns the
                  user that, except in cases of legally constituted and
                  accredited representation, no user may use the identity of
                  another person and communicate their personal data, and
                  therefore the user must at all times bear in mind that, if
                  they use E-mail, they may only include personal data
                  corresponding to their own identity and which are adequate,
                  pertinent, current, exact and true.
                </p>
                <p>
                  To this effect, the user shall be solely liable for any direct
                  and/or indirect damage caused to third parties or to{' '}
                  <strong>PLATFORM MEDIA </strong>by the use of personal data of
                  another person, or their own personal data when it is false,
                  erroneous, not current, inadequate or impertinent and without
                  consent.
                </p>
                <p>
                  Likewise, the user who communicates the personal data of a
                  third party shall beliable to the latter for the information
                  obligation established in the new regulatory framework for
                  when the personal data have not been collected from the
                  interested party and/or for the consequences of not having
                  informed him/her.
                </p>
                <p>
                  In those cases, in which the user voluntarily decides to
                  provide <strong>PLATFORM MEDIA</strong> with the personal data
                  of third parties (for example, their E-mail address), the user
                  undertakes to previously inform these persons of the intention
                  to communicate their data to <strong>PLATFORM MEDIA</strong>{' '}
                  and to obtain their free, express, specific and unequivocal
                  consent for said data processing, with the user being liable
                  if this is not the case.
                </p>
                <p>
                  <strong>8.MAINTENANCE OF DATA</strong>
                </p>
                <p>
                  The new regulatory framework obliges companies that collect
                  data to establish and inform how long the data will be kept.
                </p>
                <p>
                  The data associated with the contact form will be kept, only
                  the contact data (email and identification), for the existing
                  relationship of the request, until communication is received
                  from you associated with the exercise of rights of
                  cancellation, objection or limitation of communications.
                </p>
                <p>
                  The data associated with the payment for the contracting of an
                  online training course or other service offered by{' '}
                  <strong>PLATFORM MEDIA,</strong> will be kept in order to
                  fulfil the contractual relationship or any other aspect
                  derived from the same.
                </p>
                <p>
                  <strong>9.SECURITY AND ACCESS</strong>
                </p>
                <p>
                  In accordance with Regulation (EU) 2016/679,{' '}
                  <strong>PLATFORM MEDIA </strong>implements technical,
                  administrative, and organisational security measures to
                  protect the data we collect against accidental or unlawful
                  destruction, loss, alteration, unauthorised disclosure or
                  access, in particular where the processing involves the
                  transmission of data over a network, and against all other
                  unlawful forms of processing.
                </p>
                <p>
                  In general, in order to access and browse the contents of the
                  Website, prior registration by the user is not necessary.
                  However, the use of certain services and contents of the
                  Website may be conditional upon the prior registration of the
                  user. In this case, the data entered by the user must be
                  accurate and up to date.
                </p>
                <p>
                  The registered user shall be responsible at all times for the
                  data provided through the Website. For these purposes, the
                  completion of the data shall be deemed to be carried out by
                  the said user, who shall be liable in all cases for the data
                  entered. The user will be responsible for any false or
                  inaccurate data and information provided to{' '}
                  <strong>PLATFORM MEDIA.</strong>
                </p>
                <p>
                  Likewise, the registered user will be responsible at all times
                  for the safekeeping of their access codes (user and password),
                  assuming any damages that may arise from the improper use of
                  their access codes, as well as the transfer, revelation or
                  loss of these.
                </p>
                <p>
                  In the event that the user suspects that his/her Access codes
                  are being used by third parties without his/her prior consent,
                  he/she must notify <strong>PLATFORM MEDIA </strong>of this
                  suspicion as soon as possible via the following E-mail
                  addresses:{' '}
                  <a href="mailto:info@oxygen.education">
                    info@oxygen.education
                  </a>{' '}
                  and{' '}
                  <a href="mailto:dataprotecion@oxygen.education">
                    dataprotecion@oxygen.education
                  </a>
                  .
                </p>
                <p>
                  <strong>
                    10.CHANGES OR MODIFICATIONS TO OUR PRIVACY POLICY
                  </strong>
                </p>
                <p>
                  We may revise this Privacy Policy from time to time and may
                  post the most current version on our Website. We will inform
                  you if your rights are materially affected as a result of a
                  revision.
                </p>
              </p>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyEN;
