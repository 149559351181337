import { toast } from 'react-toastify';

const mapItemForTrack = (items) => {
  const itemFormatted = [];

  try {
    // eslint-disable-next-line
    items.map((item, index) => {
      const trackingItem = {
        item_name: item.name,
        item_id: item.id,
        item_brand:
          item.partners && item.partners[0] && item.partners[0].fullname, // Speakers
        item_category: item.oxygen_type,
        item_category_2: item.oxygen_subtype,
        item_category_3: item.type,
        index,
        quantity: 1,
        price:
          item.price ||
          (item.pricing && item.pricing[0] && item.pricing[0].price) ||
          '',
        currency:
          (item.pricing && item.pricing[0] && item.pricing[0].currency) || '',
      };

      //console.log(item, 'item');
      //console.log(trackingItem, 'trackingItem');

      itemFormatted.push(trackingItem);
    });
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      toast.error(`Error: ${e}`, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return itemFormatted;
};

const trackAction = (eventName, items) => {
  const itemsFormatted = mapItemForTrack(items);
  return trackEvent({
    event: eventName,
    ecommerce: {
      items: itemsFormatted,
    },
  });
};

export const trackEvent = (eventProps) => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer.push(eventProps);
};

export default trackAction;
