import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import EnterEmailModal from '../../Common/Modals/EnterEmail';
import { userType } from '../../../types/userType';
import i18n from '../../../languages';
import { pricingType } from '../../../types/pricingType';
import { getVariablesToSend } from '../../../utils/getUtmParams';
import { educationalResourceType } from '../../../types/educationalResourceType';
import './index.scss';
import PlayButton from '../../Common/PlayButton';
import LaLiga from '../../../assets/images/home_laliga_black.png';
import PaymentMethodModal from '../../Common/Modals/PaymentMethod';
import trackAction from '../../../utils/tracking';

const Jumbotron = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  let title = i18n.t('BRAND_TITLE');

  const params = getVariablesToSend() && `&${getVariablesToSend()}`;

  const pricingUrl =
    props.educationalResource.pricing?.length > 0 &&
    `${props.educationalResource.pricing[0].url}${params}`;

  const titleWordsQty =
    title && title.trim().replace(/\s+/gi, ' ').split(' ').length;
  if (titleWordsQty <= 3) {
    title = title.replace(/\s/g, '<br />');
  }

  const handleClickBuyButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    /*if (props.educationalResource.bought) {
      window.location.href = pricingUrl;
    }*/

    props.educationalResource &&
      trackAction('add_to_cart', [props.educationalResource]);

    setOpenPaymentModal(true);

    return false;
  };

  return (
    <div className={classnames('jumbotron')}>
      <Grid container direction="row">
        <Grid item xs={12} sm={7} className="content">
          <div className="footer-text">
            {i18n.t('BUYABLE_COURSE') + new Date().getFullYear()}
          </div>
          <Grid container direction="row" style={{ marginTop: 32 }} spacing={1}>
            <Grid item xs={12} sm={4}>
              <button
                onClick={handleClickBuyButton}
                type="button"
                style={{ width: '100%' }}
                className="btn-primary content-button arrow-right long md"
              >
                {i18n.t('BUY_INSCRIPTION')}
              </button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <button
                type="button"
                style={{ minHeight: 38, width: '100%' }}
                className="btn-secondary-white content-button md"
                onClick={() => {
                  props.handlePlayVideo();
                }}
              >
                {i18n.t('WATCH_TRAILER')}
              </button>
            </Grid>
          </Grid>
          <p>{props.short_description}</p>
          <h1>{props.name}</h1>
          <h3>
            {props.partners && props.partners[0] && props.partners[0].fullname}
          </h3>

          <div className="sponsors">
            <Grid container spacing={0}>
              <img
                src={LaLiga}
                alt="LaLiga"
                style={{ marginLeft: 4, maxHeight: 30 }}
              />
            </Grid>
          </div>
        </Grid>
        <Grid item sm={5} className="content-right-control">
          <PlayButton
            handleClick={props.handlePlayVideo}
            big={true}
            hasText={true}
          />
        </Grid>
      </Grid>

      <EnterEmailModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        idContent={props.educationalResource.id}
        contentType={props.educationalResource.type}
        course={props.educationalResource}
      />

      <PaymentMethodModal
        open={openPaymentModal}
        handleClose={() => setOpenPaymentModal(false)}
        pricingUrl={null}
        course={props.educationalResource}
        handleSelection={props.handleSelection}
      />
    </div>
  );
};

Jumbotron.propTypes = {
  educationalResource: educationalResourceType,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      fullname: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
  pricing: PropTypes.arrayOf(pricingType),
  user: userType,
  hasVideo: PropTypes.bool,
};

Jumbotron.defaultProps = {
  id: '',
  type: '',
  name: '',
  pricing: [],
  partners: [],
  user: {},
  hasVideo: false,
};

export default Jumbotron;
