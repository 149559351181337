import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import EnterEmailModal from '../../Common/Modals/EnterEmail';
import { userType } from '../../../types/userType';
import i18n from '../../../languages';
import { pricingType } from '../../../types/pricingType';
import { getVariablesToSend } from '../../../utils/getUtmParams';
import { educationalResourceType } from '../../../types/educationalResourceType';
import './index.scss';
import PlayButton from '../../Common/PlayButton';
import LaLiga from '../../../assets/images/home_laliga_black.png';
import PaymentMethodModal from '../../Common/Modals/PaymentMethod';
import trackAction from '../../../utils/tracking';
import { useEffect } from 'react';

const Jumbotron = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [name, setName] = useState('');

  let title = i18n.t('BRAND_TITLE');

  const params = getVariablesToSend() && `&${getVariablesToSend()}`;

  useEffect(() => {
    const { title: mentorName } = props;
    if (mentorName) {
      let auxname = mentorName.replace(/\n/g, '<br />');
      setName(auxname);
    }
  }, []);

  const pricingUrl =
    props.educationalResource.pricing?.length > 0 &&
    `${props.educationalResource.pricing[0].url}${params}`;

  const handleClickBuyButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.educationalResource.bought) {
      window.location.href = pricingUrl;
    }

    props.educationalResource &&
      trackAction('add_to_cart', [props.educationalResource]);

    setOpenPaymentModal(true);

    return false;
  };

  return (
    <div className={classnames('jumbotron')}>
      <Grid container direction="row">
        <Grid item xs={12} sm={7} className="content">
          <h1 dangerouslySetInnerHTML={{ __html: name }} />
        </Grid>
        <Grid item sm={5} className="content-top-control">
          <button
            type="button"
            style={{ minHeight: 38, width: '100%' }}
            className="btn-link content-button md arrow-at-left long"
            onClick={() => {
              props.history.push(props.referer);
            }}
          >
            {i18n.t('GO_BACK_TO_COURSE')}
          </button>
        </Grid>
      </Grid>

      <EnterEmailModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        idContent={props.educationalResource.id}
        contentType={props.educationalResource.type}
        course={props.educationalResource}
      />

      <PaymentMethodModal
        open={openPaymentModal}
        handleClose={() => setOpenPaymentModal(false)}
        pricingUrl={null}
        course={props.educationalResource}
        handleSelection={props.handleSelection}
      />
    </div>
  );
};

Jumbotron.propTypes = {
  educationalResource: educationalResourceType,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      fullname: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
  pricing: PropTypes.arrayOf(pricingType),
  user: userType,
  hasVideo: PropTypes.bool,
};

Jumbotron.defaultProps = {
  id: '',
  type: '',
  name: '',
  pricing: [],
  partners: [],
  user: {},
  hasVideo: false,
};

export default Jumbotron;
