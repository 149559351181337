/* eslint-disable no-undef */
import axios from 'axios';
import { config } from '../../config/config';

export const getEducationalResources = (query) =>
  axios.get(`${config.CMS_API}/courses${query}`);
/*axios.get(`${config.API_DOMAIN}/oxygen/educationalresource`, {
    withCredentials: true,
  });*/

export default { getEducationalResources };
