import {
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_BY_MENTOR_REQUEST,
  GET_COURSES_BY_MENTOR_SUCCESS,
  GET_COURSES_BY_MENTOR_ERROR,
} from '../constants';

const initialState = {
  error: null,
  loading: true,
  courses: [],
  mentorCourses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSE_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        loading: false,
      };
    case GET_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: [...action.payload.data],
        loading: false,
      };
    case GET_COURSES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COURSES_BY_MENTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_BY_MENTOR_SUCCESS:
      return {
        ...state,
        mentorCourses: [...action.payload.data],
        loading: false,
      };
    case GET_COURSES_BY_MENTOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
